import { AuthenticationDetails, ClientMetadata, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoAccessToken, CognitoUserSession, IMfaSettings, UserData, CognitoRefreshToken, CognitoIdToken, ICognitoStorage } from 'amazon-cognito-identity-js'
import config from '../config.ts';

const userPoolId = config.REACT_APP_USERPOOL_ID;
const clientId = config.REACT_APP_CLIENT_ID;

const poolData = {
  UserPoolId: `${userPoolId}`,
  ClientId: `${clientId}`,
}

const userPool: CognitoUserPool = new CognitoUserPool(poolData)

let currentUser: any = userPool.getCurrentUser();

export function getCurrentUser() {
  return currentUser
}

function getCognitoUser(username: string) {
  const userData = {
    Username: username,
    Pool: userPool,
  }
  const cognitoUser = new CognitoUser(userData)

  return cognitoUser
}

export async function getSession() {
  if (!currentUser) {
    currentUser = userPool.getCurrentUser()
  }

  return new Promise(function (resolve, reject) {
    currentUser.getSession(function (err: any, session: any) {
      if (err) {
        reject(err)
      } else {
        resolve(session)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function signUpUserWithEmail(username: string, password: string, attributes:any, metaData:ClientMetadata) {
  return new Promise(function (resolve, reject) {
    // const attributeList:[] = [];

    userPool.signUp(username, password, attributes, [], function (err, res) {
      if (err) {
        //console.log('err', err);
        reject(err)
      } else {
        //console.log('res', res);
        resolve(res)
      }
    }, metaData)
  }).catch((err) => {
    throw err
  })
}

export async function verifyCode(username: string, code: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username)

    cognitoUser.confirmRegistration(code, true, function (err, result) {
      if (err) {
        reject(err)
      } else {
        resolve(result)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function signInWithEmail(username: string, password: string) {
  return new Promise(function (resolve, reject) {
    const authenticationData = {
      Username: username,
      Password: password,
      ValidationData: {Name: 'FirstLogin', Value: 'true'}
    }
    const authenticationDetails = new AuthenticationDetails(authenticationData)

    currentUser = getCognitoUser(username);

    currentUser.authenticateUser(authenticationDetails, {
      onSuccess: async function (res: any, userConfirmationNecessary:boolean = true) {
        if(config.FORCE_MFA_TOTP){
          await retrieveUserData().then(function(result){
            if(localStorage.getItem('isMFAToConfig') === 'true'){
              resolve(res);
            }
          }).catch(function(error){
            reject(error);
          })
        }
        

        let userSub:string = currentUser.getUsername();
        let lastDateDeviceRemembered: string | null = localStorage.getItem(userSub);
        if(lastDateDeviceRemembered && (((new Date()).getTime()-(new Date(lastDateDeviceRemembered)).getTime())/1000 > config.DAY_IN_SECONDS )){
          try{
            let forgotResult = await forgotDeviceOnLogin(userSub)
              //console.log(forgotResult);
              resolve(forgotResult);
              return;
            }catch(error){
              //console.log(error);
              reject(error);
           }
        }
        
        resolve(res)
      },
      onFailure: function (err: any) {
        //console.log('reject')
        //console.log(typeof err)
        if(config.FORCE_MFA_TOTP){
          reject({forceTotp: true});
        }else{
          //nel caso in cui il totp non sia obbligatorio la procedura fallisce
          reject(err)
        }
      },
      newPasswordRequired: function(res: any, userAttributes:any, requiredAttributes:any) {
        // User was signed up by an admin and must provide new
        // password and required attributes, if any, to complete
        // authentication.

        // the api doesn't accept this field back
        delete userAttributes.email_verified;
        
        currentUser.completeNewPasswordChallenge(config.TMP_USER_CREATED_PSW, userAttributes, this);
        // store userAttributes on global variable
        //sessionUserAttributes = userAttributes;
      },
      mfaRequired: function(challengeName:any, challengeParameters:any){
        reject('No Challenge');
      },
      totpRequired: function(challengeName:any, challengeParameters:any){
        resolve('SOFTWARE_TOKEN_MFA');
      }
    })
  }).catch((err) => {
    throw err
  })
}

async function retrieveUserData(){
  return new Promise(function (resolve, reject){
    currentUser.getUserData( function(err: Error, response:UserData){
      if(err){
        reject('Invalid User')
      }else{
        const resKeys = Object.keys(response);
        if(!resKeys.includes('UserMFASettingList')){
          localStorage.setItem('isMFAToConfig', 'true');
        }
        resolve(response);
      }
    }, {})
  })
}

async function forgotDeviceOnLogin(sub: string){
  return new Promise(function (resolve, reject){
    currentUser.getCachedDeviceKeyAndPassword();
    currentUser.forgetDevice({
      onSuccess: function(success:string) {
        localStorage.removeItem(sub);
        resolve("SOFTWARE_TOKEN_MFA");
      },
      onFailure: function(err:Error) {
        alert(err.message || JSON.stringify(err));
        reject(false);
      }
    })
  })
} 

export async function refreshToken(session: any) {
  //console.log('quello che arriva', session)
  return new Promise(async function (resolve, reject) {

    const RefreshToken = new CognitoRefreshToken({RefreshToken: session.refreshToken.token})

    currentUser.refreshSession(RefreshToken, function (err: any, result: any) {
      if (err) {
        reject(err)
      } else {
        resolve(result)
      }
    })

  }).catch((err) => {
    throw err
  })  
}

export async function signOut() {
  if (currentUser) {
    currentUser.signOut()
  }
}

export async function getAttributes() {
  return new Promise(function (resolve, reject) {
    currentUser.getUserAttributes(function (err: any, attributes: any) {
      if (err) {
        reject(err)
      } else {
        resolve(attributes)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function setAttribute(attribute: any) {
  return new Promise(function (resolve, reject) {
    const attributeList = []
    const res = new CognitoUserAttribute(attribute)
    attributeList.push(res)

    currentUser.updateAttributes(attributeList, (err: any, res: any) => {
      if (err) {
        reject(err)
      } else {
        resolve(res)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function sendCode(email: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(email)
    if (!cognitoUser) {
      reject(`could not find ${email}`)
      return
    }

    cognitoUser.forgotPassword({
      onSuccess: function (res) {
        resolve(res)
      },
      onFailure: function (err) {
        reject(err)
      },
    })
  }).catch((err) => {
    throw err
  })
}

export async function forgotPassword(username: string, code: string, password: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username)

    if (!cognitoUser) {
      reject(`could not find ${username}`)
      return
    }

    cognitoUser.confirmPassword(code, password, {
      onSuccess: function () {
        resolve('password updated')
      },
      onFailure: function (err) {
        reject(err)
      },
    })
  })
}

export async function changePassword(oldPassword: string, newPassword: string) {
  return new Promise(function (resolve, reject) {
    currentUser.changePassword(oldPassword, newPassword, function (err: any, res: any) {
      if (err) {
        reject(err)
      } else {
        resolve(res)
      }
    })
  })
}

export async function isAccessTokenValid(accessToken: string){
  const token = new CognitoAccessToken({ AccessToken: accessToken });
  const exp = token.getExpiration();

  if (Date.now() >= exp * 1000) {
    return false;
  }else{
    return true;
  }
}

export async function requestTokenTOTP(){
  return new Promise(function (resolve, reject) {
    currentUser.associateSoftwareToken({
      associateSecretCode: async(secretCode:string) => {
        let userEmailObj:any = await getAttributes()
        let useremail:string =  userEmailObj.find((attribute:{Name: string, Value: string}) => attribute.Name === 'email').Value;
        const url = `otpauth://totp/${useremail}?secret=${secretCode}&issuer=${config.ISSUER_MFA}`
        resolve({qrCodeUrl: url, secretCode})
      },
      onFailure: (err:any) => {
        reject(err)
      }
    });
  })
}

export async function verifySoftwareTOTP(code: string){
  return new Promise(function (resolve, reject) {
    currentUser.verifySoftwareToken(code, 'totp device', {
      onSuccess: (session:CognitoUserSession) => {
        //console.log(session);
        resolve(session);
      },
      onFailure: (err: Error) => {
        //console.log(err);
        reject(err);
      }
    })
  })
}

const enableAsPreferredTOTP:IMfaSettings = {
  PreferredMfa: true,
  Enabled: true
}

export async function enableMFA() {
  return new Promise(function (resolve, reject) {
    currentUser.setUserMfaPreference(null, enableAsPreferredTOTP, function( err:Error, res:string){
      if(err){
        reject(err)
      }else{
        //console.log(res)
        resolve(res)
      }
    })
  })
}

export async function signInWithTOTP(totpCode:string, username: string, password: string){
  return new Promise(function (resolve, reject){
    
    const authenticationData = {
      Username: username,
      Password: password,
    }
    const authenticationDetails = new AuthenticationDetails(authenticationData)

    currentUser = getCognitoUser(username)

    currentUser.authenticateUser(authenticationDetails, {
      totpRequired: function(challengeName:any, challengeParameters:any){
        currentUser.sendMFACode( totpCode, {
          onSuccess: (session: CognitoUserSession, userConfirmationNecessary: boolean = true) => {
              currentUser.getCachedDeviceKeyAndPassword()
                if (userConfirmationNecessary){
                  currentUser.setDeviceStatusRemembered({
                    onSuccess: (success:string) => {
                      const userSub:string = currentUser.getUsername(); 
                      localStorage.setItem(userSub, (new Date()).toString());
                    },
                    onFailure: (err:any) => {
                      reject(err)
                    }})
                }
            resolve(session);
          },
          onFailure: (err: any) => {
            reject(err)
          }
      } , "SOFTWARE_TOKEN_MFA" )},
      onFailure: function (err: any) {
        reject(err)
      },
    })
  })
}

export async function rememberDevice(){
  return new Promise(function (resolve, reject){
    currentUser.getCachedDeviceKeyAndPassword()
    currentUser.setDeviceStatusRemembered({
      onSuccess: (success:string) => {
        const userSub:string = currentUser.getUsername(); 
        localStorage.setItem(userSub, (new Date()).toString());
        resolve(success);
      },
      onFailure: (err:any) => {
        reject(err)
      }})
   })

}

export async function getDevice() {
  return new Promise(function (resolve, reject){
    currentUser.getCachedDeviceKeyAndPassword();
    currentUser.getDevice({
      onSuccess: (success:string) => {
        resolve(success);
      },
      onFailure: (err:Error) => {
        reject(err);
      }
    })
  })
}

export async function forceForgotDevice(){
  return new Promise(function (resolve, reject){
    //currentUser.getCachedDeviceKeyAndPassword();
    currentUser.forgetDevice({
      onSuccess: function(success:string) {
        resolve(success)
      },
      onFailure: function(err:Error) {
        alert(err.message || JSON.stringify(err));
        reject(err)
      },
    });
  })
}

export async function checkIfUserHasMFA(){
  return new Promise(function (resolve, reject){
    currentUser.getUserData( function(err: Error, response:UserData){
      if(err){
        reject('Invalid User')
      }else{
        const resKeys = Object.keys(response);
        if(!resKeys.includes('UserMFASettingList')){
          localStorage.setItem('isMFAToConfig', 'true');
          reject('NoMFA_ACTIVE');
        }else{
          resolve('MFA_ACTIVE');
        }
      }
    }, { bypassCache: true })
  })
}