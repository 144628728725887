import React, {useContext, useEffect, useState, useMemo, useRef} from 'react';
import './Dashboard.css';
import { AppAuthGlobalVariables } from '../../../contexts/AppAuthContext';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../../contexts/authContext';
import config from '../../../config';
import { NotificationManager } from 'react-notifications';
import Graph from '../../../utils/Graph';
import CircleProgress from '../../../components/CircleProgress/CircleProgress';
import { formatNumbersIt, inputOnlyNumbers } from '../../../utils/CommonUtilities';
import TextField from '@mui/material/TextField';
import { PercentageIcon } from '../../../assets/icons/icons';
import CircularProgress from '@mui/material/CircularProgress';

const arrMonths = ['GEN', 'FEB', 'MAR', 'APR', 'MAG', 'GIU', 'LUG', 'AGO', 'SET', 'OTT', 'NOV', 'DIC']

const tooltipMaker = (data:any) => {
	let descrizioneSplit = data.label.split(' - ');

  	const isFirstHalf = data.x < 700 / 2;
	return (
		<div style={{
				background: "white",
				color: "inherit",
				fontSize: "13px",
				borderRadius: "2px",
				boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
				padding: "5px 9px",
				right: isFirstHalf ? 'unset' : 0,
				left: isFirstHalf ? 0 : 'unset',
				bottom: 5,
				position: 'absolute'
			}}
		>
			<div style={{
					whiteSpace: "pre",
					display: "flex",
					alignItems: "center"
				}}
			>
				{
					data.data.GRAFICO==="RICAVI"
					?
						data.data.ETICHETTA==="FATTURATO" || data.data.ETICHETTA==="DA FATTURARE" 
						?
							<>
								<span style={{display: "block", width: "12px", height: "12px", background: `${data.color}`, marginRight: "7px"}}></span>
								<span>{descrizioneSplit[0].split('_')[0] + ' - ' + descrizioneSplit[1]}: <strong>{formatNumbersIt(data?.value || 0)}</strong></span>
							</>
						:
							<div className='first-graph-stacked-cols'>
								{data.data.IMPORTO_FATT_DIR&&(
									<div className='first-graph-stacked-cols-element'>
										<span style={{display: "block", width: "12px", height: "12px", background: `${data.data.IMPORTO_FATT_DIRColor}`, marginRight: "7px"}}></span>
										<span>FATTURAZIONE DIRETTA TALENT - {descrizioneSplit[1]}: <strong>{formatNumbersIt(data?.data?.IMPORTO_FATT_DIR || 0)}</strong></span>
									</div>
								)}
								<div className='first-graph-stacked-cols-element'>
									<span style={{display: "block", width: "12px", height: "12px", background: `${data.data.IMPORTO_NO_FATT_DIRColor}`, marginRight: "7px"}}></span>
									<span>{descrizioneSplit[0].split('_')[0] + ' - ' + descrizioneSplit[1]}: <strong>{formatNumbersIt(data?.data?.IMPORTO_NO_FATT_DIR || 0)}</strong></span>
								</div>
							</div>
					:
						data.data.GRAFICO==="FATTURATO"
						?
							<div className='first-graph-stacked-cols'>
								<div className='first-graph-stacked-cols-element'>
									<span style={{display: "block", width: "12px", height: "12px", background: `${data.data.FATTURATOColor}`, marginRight: "7px"}}></span>
									<span>{data.id === 'FATTURATO' ? data.label : 'FATTURATO - ' + data.data.ANNO_MESE}: <strong>{formatNumbersIt(data?.data?.FATTURATO || 0)}</strong></span>
								</div>
								<div className='first-graph-stacked-cols-element'>
									<span style={{display: "block", width: "12px", height: "12px", background: `${data.data.DA_FATTURAREColor}`, marginRight: "7px"}}></span>
									<span>{data.id === 'DA_FATTURARE' ? data.label.replace('_', ' ') : 'DA FATTURARE - ' + data.data.ANNO_MESE}: <strong>{formatNumbersIt(data?.data?.DA_FATTURARE || 0)}</strong></span>
								</div>
							</div>
						:
						<>
							<span style={{display: "block", width: "12px", height: "12px", background: `${data.color}`, marginRight: "7px"}}></span>
							<span>{descrizioneSplit[0].replace('_', ' ') + ' - ' + descrizioneSplit[1]}: <strong>{formatNumbersIt(data?.value || 0)}</strong></span>
						</>
				}
				
			</div>
		</div>
	)
}

const tooltipMakerPie = ({datum}:any) => {
	return (
		<div style={{
				background: "white",
				color: "inherit",
				fontSize: "13px",
				borderRadius: "2px",
				boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
				padding: "5px 9px"
			}}
		>
			<div style={{
					whiteSpace: "pre",
					display: "flex",
					alignItems: "center"
				}}
			>
				<span style={{display: "block", width: "12px", height: "12px", background: `${datum.color}`, marginRight: "7px"}}></span>
				<span>{datum.label}: <strong>{formatNumbersIt(datum.value)}%</strong></span>
			</div>
		</div>
	)
}

function Dashboard(props:any) {

	const { setPageName } = useContext(AppAuthGlobalVariables);
	const auth = useContext(AuthContext);
	const urlParams = useParams();
	
	const [margineDoomNettoDn, setMargineDoomNettoDn] = useState('');
	const [margineDoomEffettivo, setMargineDoomEffettivo] = useState('');
	const [firstGraphLoading, setFirstGraphLoading] = useState(true);
	const [secondGraphLoading, setSecondGraphLoading] = useState(true);
	const [thirdGraphLoading, setThirdGraphLoading] = useState(true);
	const [fourthGraphLoading, setFourthGraphLoading] = useState(true);
	const [firstData, setFirstData] = useState([]);
	const [secondData, setSecondData] = useState([]);
	const [thirdData, setThirdData] = useState([]);
	const [fourthData, setFourthData] = useState([]);

	const [anno, setAnno] = useState<any>(new Date().getFullYear().toString());

	useEffect(() => {
		async function init(){
			setPageName?.(urlParams['*']);
			await getGraphsData();
		}
		init();
	},[])

	useEffect(() => {
        async function init() {
            if(anno.length===4 && firstGraphLoading === false && secondGraphLoading === false && thirdGraphLoading === false && fourthGraphLoading === false){
                await getGraphsData();
            }
        }
        init();
    },[ anno ]);

	const getGraphsData = async() => {
		try{
			let axiosParams = {
				url: 'graphs',
				method: 'get',
				baseURL: config.API_URL,
				headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
				params: {
					anno
				}
			}
  
        	let response = await axios(axiosParams);

			if(response.status === 200){
				//setRows(response.data);
				let parsedAnno = parseInt(anno);
				const data = response.data;
				let firstGraphFiltered = data.filter((el:any) => el.GRAFICO==='RICAVI');

				//let secondGraphFiltered = data.filter((el:any) => el.GRAFICO==="ANDAMENTO");
				let actualSecondGraph = data.filter((el:any) => el.GRAFICO==="ANDAMENTO"&&el.ANNO===parsedAnno);
				let prevYearSecondGraph = data.filter((el:any) => el.GRAFICO==="ANDAMENTO"&&el.ANNO===parsedAnno-1);

				let thirdGraphFiltered = data.filter((el:any) => el.GRAFICO==="REDEMPTION");

				let fourthGraphData = data.filter((el:any) => el.GRAFICO==="FATTURATO");
				fourthGraphData = fourthGraphData.map((el:any) => {el.ANNO_MESE = `${arrMonths[el.MESE]} ${el.ANNO.toString().substring(2,4)}`; return el} );
				let actualFourthGraph = fourthGraphData.filter((el:any) => el.ANNO===parsedAnno);
				let prevYearFourthGraph = fourthGraphData.filter((el:any) => el.ANNO===parsedAnno-1);
				//let fourthGraphFiltered = data.filter((el:any) => el.GRAFICO==="TOP");

				let marginsLabels = data.filter((el:any) => el.GRAFICO==="MARGINI");

				//manipolazione del primo grafico per colonne extra
				let mergedFirstGraphColsPerFlagFattTalent: any = [];
				for (let index = 0; index < firstGraphFiltered.length; index++) {
					const element = firstGraphFiltered[index];

					if(element.ETICHETTA === "DA FATTURARE" || element.ETICHETTA === "FATTURATO"){
						element.IMPORTO_NO_FATT_DIR = element.IMPORTO;
						mergedFirstGraphColsPerFlagFattTalent.push(element);
						continue;
					}

					let alreadyPresentInMerged = mergedFirstGraphColsPerFlagFattTalent.find((item:any) => item.ETICHETTA === element.ETICHETTA);
					if(!alreadyPresentInMerged){
						let oppostoFlag = firstGraphFiltered.find((item:any) => item.ETICHETTA === element.ETICHETTA && item.FLAG_FATT_DIR_TALENT !== element.FLAG_FATT_DIR_TALENT);
						if(oppostoFlag){
							if(element.FLAG_FATT_DIR_TALENT === 'Y'){
								element.IMPORTO_FATT_DIR = element.IMPORTO ? element.IMPORTO : null;
								element.IMPORTO_NO_FATT_DIR = oppostoFlag.IMPORTO ? oppostoFlag.IMPORTO : null;
							}else if(element.FLAG_FATT_DIR_TALENT === 'N'){
								element.IMPORTO_FATT_DIR = oppostoFlag.IMPORTO ? oppostoFlag.IMPORTO : null;
								element.IMPORTO_NO_FATT_DIR = element.IMPORTO ? element.IMPORTO : null;
							}
							mergedFirstGraphColsPerFlagFattTalent.push(element);
						}else{
							//se non trovo l'oppost flag fatt dir talent vuol dire che non trovo il dato e quindi lo aggiungo
							if(element.FLAG_FATT_DIR_TALENT === 'Y'){
								element.IMPORTO_FATT_DIR = element.IMPORTO;
							}else if(element.FLAG_FATT_DIR_TALENT === 'N'){
								element.IMPORTO_NO_FATT_DIR = element.IMPORTO;
							}
							mergedFirstGraphColsPerFlagFattTalent.push(element);
						}
					}else{
						continue;
					}
				}
				let reorderedFirstGraph:any = [{},{},{},{},{}];
				for (let index = 0; index < mergedFirstGraphColsPerFlagFattTalent.length; index++) {
					const element = mergedFirstGraphColsPerFlagFattTalent[index];
					
					switch (element.ETICHETTA) {
						case 'LEAD':
							//element.color = '#cbe1f2';
							element["IMPORTO_NO_FATT_DIRColor"] =  '#A2B4C1'
							element["IMPORTO_FATT_DIRColor"] = '#cbe1f2'
							reorderedFirstGraph[0] = element;
							break;
						case 'TOTALE':
							//element.color = '#FF9D60';
							element["IMPORTO_NO_FATT_DIRColor"] = '#FF9D60'
							element["IMPORTO_FATT_DIRColor"] = '#FFCEB0'
							reorderedFirstGraph[1] = element;
						break;
						case 'RIFIUTATE':
							//element.color = '#F78E9C';
							element["IMPORTO_NO_FATT_DIRColor"] =  '#F78E9C'
							element["IMPORTO_FATT_DIRColor"] = '#ed6b7c'
							reorderedFirstGraph[2] = element;
						break;
						case 'FATTURATO':
							//element.color = '#CA85AC';//'#bbfbb1';
							element["IMPORTO_NO_FATT_DIRColor"] =  '#CA85AC'
							element["IMPORTO_FATT_DIRColor"] = '#CA85AC'
							reorderedFirstGraph[3] = element;
						break;
						case 'DA FATTURARE':
							//element.color = '#E1B4CD';//'#fbf1b1';
							element["IMPORTO_NO_FATT_DIRColor"] =  '#E1B4CD'
							element["IMPORTO_FATT_DIRColor"] = '#E1B4CD'
							reorderedFirstGraph[4] = element;
						break;
					
						default:
							break;
					}
				}

				for (let index = 0; index < reorderedFirstGraph.length; index++) {
					let element = reorderedFirstGraph[index];
					
					if(!element?.ETICHETTA){
						switch(index){
							case 0:
								element.GRAFICO = "RICAVI";
								element.ETICHETTA = "LEAD";
								element.FLAG_FATT_DIR_TALENT = "N";
								element.ANNO = parsedAnno;
								element.MESE = null;
								element.IMPORTO = 0;
								element.IMPORTO_NO_FATT_DIR = 0;
								element.IMPORTO_NO_FATT_DIRColor = "#A2B4C1";
								element.IMPORTO_FATT_DIRColor = "#cbe1f2";
								break;
							case 1:
								element.GRAFICO = "RICAVI";
								element.ETICHETTA = "TOTALE";
								element.FLAG_FATT_DIR_TALENT = "Y";
								element.ANNO = parsedAnno;
								element.MESE = null;
								element.IMPORTO = 0;
								element.IMPORTO_FATT_DIR = 0;
								element.IMPORTO_NO_FATT_DIR = 0;
								element.IMPORTO_NO_FATT_DIRColor = "#FF9D60";
								element.IMPORTO_FATT_DIRColor = "#FFCEB0";
								break;
							case 2:
								element.GRAFICO = "RICAVI";
								element.ETICHETTA = "RIFIUTATE";
								element.FLAG_FATT_DIR_TALENT = "N";
								element.ANNO = parsedAnno;
								element.MESE = null;
								element.IMPORTO = 0;
								element.IMPORTO_NO_FATT_DIR = 0;
								element.IMPORTO_NO_FATT_DIRColor = "#F78E9C";
								element.IMPORTO_FATT_DIRColor = "#ed6b7c";
								break;
							case 3:
								element.GRAFICO = "RICAVI";
								element.ETICHETTA = "FATTURATO";
								element.FLAG_FATT_DIR_TALENT = null;
								element.ANNO = parsedAnno;
								element.MESE = null;
								element.IMPORTO = 0;
								element.IMPORTO_NO_FATT_DIR = 0;
								element.IMPORTO_NO_FATT_DIRColor = "#CA85AC";
								element.IMPORTO_FATT_DIRColor = "#CA85AC";
								break;
							case 4:
								element.GRAFICO = "RICAVI";
								element.ETICHETTA = "DA FATTURARE";
								element.FLAG_FATT_DIR_TALENT = null;
								element.ANNO = parsedAnno;
								element.MESE = null;
								element.IMPORTO = 0;
								element.IMPORTO_NO_FATT_DIR = 0;
								element.IMPORTO_NO_FATT_DIRColor = "#E1B4CD";
								element.IMPORTO_FATT_DIRColor = "#E1B4CD";
								break;
						}
					}
				}

				for (let index = 0; index < actualSecondGraph.length; index++) {
					const element = actualSecondGraph[index];

					let foundPrevYearMatch = prevYearSecondGraph.find((el:any) => el.MESE === element.MESE);
					if(foundPrevYearMatch){
						element.PREVIOUS_YEAR = foundPrevYearMatch.IMPORTO;
					}

					element.MESE_IN_LETTERE = arrMonths[element.MESE];
					element.ACTUAL = element.IMPORTO;
				}
				//ciclo sui prev e mi salvo quelli non trovati con coincidenza nell'anno attuale
				let noMatchPrevYear = [];
				for (let index = 0; index < prevYearSecondGraph.length; index++) {
					const element = prevYearSecondGraph[index];

					let foundActualYearMatch = actualSecondGraph.find((el:any) => el.MESE === element.MESE);
					if(!foundActualYearMatch){
						element.PREVIOUS_YEAR = element.IMPORTO;
						element.ACTUAL = 0;
						element.MESE_IN_LETTERE = arrMonths[element.MESE];
						noMatchPrevYear.push(element);
					}
				}
				let sortedSecondGraph:any = [...noMatchPrevYear,...actualSecondGraph].sort((a:any, b:any) => a.MESE > b.MESE ? 1 : a.MESE < b.MESE ? -1 : 0 );

				for (let index = 0; index < thirdGraphFiltered.length; index++) {
					let element:any = thirdGraphFiltered[index];
					thirdGraphFiltered[index] = {
						"id": element.ETICHETTA,
						"label": element.ETICHETTA,
						"value": element.IMPORTO,
						"color": (() => {
							switch (element.ETICHETTA) {
								case "LEAD":
									return "#cbe1f2";
								case "CONFERMATE":
									return "#fbf1b1";
								case "CONTRATTUALIZZATE":
									return "#bbfbb1";
								case "RIFIUTATE":
									return "#F78E9C";
							}
						})()
					}
				}


				let actualFourthGraphMergedMonth:any = []
				let prevYearFourthGraphMergedMonth:any = []
				for (let index = 0; index < actualFourthGraph.length; index++) {
					const element = actualFourthGraph[index];
					
					if(!actualFourthGraphMergedMonth.some((el:any) => el.ANNO_MESE === element.ANNO_MESE)){
						let foundYearMonthMatch = actualFourthGraph.find((el:any) => el.MESE === element.MESE && el.ETICHETTA !== element.ETICHETTA);
						if(foundYearMonthMatch){
							if(element.ETICHETTA === 'FATTURATO'){
								element.FATTURATO = element.IMPORTO;
								element.DA_FATTURARE = foundYearMonthMatch.IMPORTO;
							}else if(element.ETICHETTA === 'DA FATTURARE'){
								element.FATTURATO = foundYearMonthMatch.IMPORTO;
								element.DA_FATTURARE = element.IMPORTO;
							}
						}else{
							if(element.ETICHETTA === 'FATTURATO'){
								element.FATTURATO = element.IMPORTO;
							}else if(element.ETICHETTA === 'DA FATTURARE'){
								element.DA_FATTURARE = element.IMPORTO;
							}
						}
						element.DA_FATTURAREColor = '#E1B4CD';
						element.FATTURATOColor = '#CA85AC';
						actualFourthGraphMergedMonth.push(element);
					}
				}

				for (let index = 0; index < prevYearFourthGraph.length; index++) {
					const element = prevYearFourthGraph[index];
					
					if(!prevYearFourthGraphMergedMonth.some((el:any) => el.ANNO_MESE === element.ANNO_MESE)){
						let foundYearMonthMatch = prevYearFourthGraph.find((el:any) => el.MESE === element.MESE && el.ETICHETTA !== element.ETICHETTA);
						if(foundYearMonthMatch){
							if(element.ETICHETTA === 'FATTURATO'){
								element.FATTURATO = element.IMPORTO;
								element.DA_FATTURARE = foundYearMonthMatch.IMPORTO;
							}else if(element.ETICHETTA === 'DA FATTURARE'){
								element.FATTURATO = foundYearMonthMatch.IMPORTO;
								element.DA_FATTURARE = element.IMPORTO;
							}
						}else{
							if(element.ETICHETTA === 'FATTURATO'){
								element.FATTURATO = element.IMPORTO;
							}else if(element.ETICHETTA === 'DA FATTURARE'){
								element.DA_FATTURARE = element.IMPORTO;
							}
						}
						element.DA_FATTURAREColor = '#E1B4CD';
						element.FATTURATOColor = '#CA85AC';
						prevYearFourthGraphMergedMonth.push(element);
					}
				}
				
				let sortedFourthGraph:any = [...actualFourthGraphMergedMonth,...prevYearFourthGraphMergedMonth].sort((a:any, b:any) => a.MESE > b.MESE ? 1 : a.MESE < b.MESE ? -1 : 0 );

				for (let index = 0; index < sortedFourthGraph.length; index++) {
					const element = sortedFourthGraph[index];

					if(!element?.FATTURATO)element.FATTURATO=0;
					if(!element?.DA_FATTURARE)element.DA_FATTURARE=0;
				}

				setFirstData(reorderedFirstGraph);
				setSecondData(sortedSecondGraph);
				setThirdData(thirdGraphFiltered);
				setFourthData(sortedFourthGraph);
				setMargineDoomNettoDn(formatNumbersIt(marginsLabels.find((marg:any) => marg.ETICHETTA === 'AVG_MARG_DOOM_DN')?.IMPORTO || 0));
				setMargineDoomEffettivo(formatNumbersIt(marginsLabels.find((marg:any) => marg.ETICHETTA === 'AVG_MARG_DOOM_FATT')?.IMPORTO || 0));

				setFirstGraphLoading(false);
				setSecondGraphLoading(false);
				setThirdGraphLoading(false);
				setFourthGraphLoading(false);
			}
		}catch (e:any){
			//setLoading(false);
			if(e?.response?.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e?.response?.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
	}

	return (
		<div className='component-container dashboard'>
			<div className='component-card'>
				<div className='graph-wrapper graph-box'>
					{firstGraphLoading ?
						<CircularProgress />
					:
						<Graph
							graphType='bar'
							data={firstData}
							indexBy='ETICHETTA'
							layout='vertical'
							groupMode='stacked'
							keys={['IMPORTO_FATT_DIR', 'IMPORTO_NO_FATT_DIR']}
							margin={{ top: 20, right: 0, bottom: 60, left: 80 }}
							padding={0.3}
							maxValue={'auto'}
							axisBottom={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: 'STATUS',
								legendPosition: 'start',
								legendOffset: 36,
								truncateTickAt: 0
							}}
							axisLeft={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: 'SINTESI',
								legendPosition: 'middle',
								legendOffset: -70,
								truncateTickAt: 0,
								format: (s:any) => {
									let value = parseInt(s);
									return formatNumbersIt(value);
								}
							}}
							label={(s:any) => formatNumbersIt(s.value)}
							colorFromColumnData={true}
							colorBy="id"
							tooltip={(el:any) => tooltipMaker(el)}
							showLegend={false}
						/>
					}
				</div>
				<div className='graph-wrapper graph-box'>
					{secondGraphLoading ?
						<CircularProgress />
					:
						<Graph
							graphType='bar'
							data={secondData}
							indexBy='MESE_IN_LETTERE'
							layout='vertical'
							groupMode='grouped'
							keys={['ACTUAL','PREVIOUS_YEAR']}
							margin={{ top: 20, right: 0, bottom: 60, left: 80 }}
							padding={0.3}
							maxValue={'auto'}
							axisBottom={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: 'ACTUAL MENSILIZZATO',
								legendPosition: 'middle',
								legendOffset: 36,
								truncateTickAt: 0
							}}
							axisLeft={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: 'CONF. + TUTTE CONTRATTUALIZZATE + CHIUSE',
								legendPosition: 'middle',
								legendOffset: -70,
								truncateTickAt: 0,
								format: (s:any) => {
									let value = parseInt(s);
									return formatNumbersIt(value);
								}
							}}
							label={(s:any) => formatNumbersIt(s.value)}
							colorFromColumnData={["#FF9D60","#FFBB60"]}
							tooltip={(el:any) => tooltipMaker(el)}
							showLegend={false}
							enableLabel={false}
						/>
					}
				</div>
				<div className='graph-wrapper with-margz'>
					<div className='margz-wrap'>
						<div className='margz-values'>
							<TextField 
								id="standard-basic" 
								variant="outlined" 
								title='Margine Doom Medio Al Lordo Del DN' 
								label="Margine Doom Medio Al Lordo Del DN" 
								value={margineDoomNettoDn} 
								inputProps={{readOnly: true,sx:{textAlign:'right'}, className:'resized-pad-text-field'}}  
								InputProps = {{ endAdornment: <PercentageIcon addClass='pad-left'/> }} 
								//sx={{width: '50%'}}
							/>
							<TextField 
								id="standard-basic" 
								variant="outlined" 
								title='Margine Doom Medio Effettivo Fatturato' 
								label="Margine Doom Medio Effettivo Fatturato" 
								value={margineDoomEffettivo} 
								inputProps={{readOnly: true,sx:{textAlign:'right'}, className:'resized-pad-text-field'}}  
								InputProps = {{ endAdornment: <PercentageIcon addClass='pad-left'/> }} 
								//sx={{width: '50%'}}
							/>
						</div>
						<TextField 
							className='costi-extra-cuscinetto-bg'
							id="anno" 
							label="Anno" 
							variant="standard" 
							value={anno}
							onChange={(event) => {if(inputOnlyNumbers(event.target.value))setAnno(event.target.value)}} 
							inputProps={{maxLength: 4, width: '80px'}}
							error={anno.length!==4}
							sx={{maxWidth:'100px', width:'unset'}}
						/>
					</div>
					<div className='graph-box'>
						{thirdGraphLoading ?
							<CircularProgress />
						:
							<div className='third-graph-wrapper'>
								<Graph
									graphType='pie'
									data={thirdData}
									margin={{ top: 20, right: 80, bottom: 40, left: 80 }}
									innerRadius={0.3}
									padAngle={0.7}
									cornerRadius={3}
									tooltip={(el:any)=>tooltipMakerPie(el)}
								/>
							</div>
						}
					</div>
				</div>
				<div className='graph-wrapper graph-box'>
					{fourthGraphLoading ?
						<CircularProgress />
					:
						<Graph
							graphType='bar'
							data={fourthData}
							indexBy='ANNO_MESE'
							layout='vertical'
							groupMode='stacked'
							keys={['DA_FATTURARE','FATTURATO']}
							margin={{ top: 50, right: 0, bottom: 50, left: 80 }}
							padding={0.3}
							maxValue={'auto'}
							axisBottom={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: -35,
								legend: 'FATTURATO MENSILIZZATO',
								legendPosition: 'middle',
								legendOffset: 45,
								truncateTickAt: 0,
								format: (s:any) => {
									const separator = '●';
									let MAXLABELLENGTH = 18;
									let nPieceLength   = ~~( ( MAXLABELLENGTH - 3 ) / 2 );
									const s1 = ( ( s === 0 ? 0 : ( s || '' ) ) + '' );
									const s2 = s1.replaceAll(separator, separator.trim() ); 
									return ( s1.length < MAXLABELLENGTH ) ? s1 : ( s2.slice(0,nPieceLength) + '...' + s2.slice(-nPieceLength) );
								}
							}}
							axisLeft={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: 'TUTTE CONTRATTUALIZZATE + CHIUSE',
								legendPosition: 'middle',
								legendOffset: -70,
								truncateTickAt: 0,
								format: (s:any) => {
									let value = parseInt(s);
									return formatNumbersIt(value);
								}
							}}
							label={(s:any) => formatNumbersIt(s.value)}
							//colorFromColumnData={["#FF9D60","#FFCEB0"]}
							colorFromColumnData={true}
							colorBy="id"
							tooltip={(el:any) => tooltipMaker(el)}
							showLegend={false}
							enableLabel={false}
						/>
					} 
				</div>
			</div>
		</div>
	)
}

export default Dashboard;