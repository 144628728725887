import React, {useContext, useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { Button
        ,Dialog
        ,DialogActions
        ,DialogContent
        ,DialogTitle
        ,IconButton
        ,TextField } from '@mui/material';
import axios  		    from 'axios';
import config 		    from '../../../../config';
import CircleProgress   from '../../../../components/CircleProgress/CircleProgress';
import { OptionParams } from '../../../../utils/ConfigInterfaces'
import { AuthContext }  from '../../../../contexts/authContext';
import { AppAuthGlobalVariables } from '../../../../contexts/AppAuthContext';
import { AddIcon
       , CheckBoxEmptyIcon
       , CheckBoxFilledIcon
       , EditIcon } from '../../../../assets/icons/icons';
import './Aree.css';
import '../Options.css';
import NoRowsDataGridComponent from '../../../../components/NoRowsDataGridComponent/NoRowsDataGridComponent';
import { DataGridPremium,
	LicenseInfo,
	GridColDef,
	GridRowModel
	} from '@mui/x-data-grid-premium';

LicenseInfo.setLicenseKey(
    'a5fb85400407876bcf9c1364b93581a5Tz0xMDEzNzksRT0xNzYyNTM4ODk3MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y',
);


function Aree(props:any) {
    const auth = useContext(AuthContext);
    const urlParams = useParams(); 
    const { setPageName } = useContext(AppAuthGlobalVariables);

    const [ openDialog    ,setOpenDialog    ] = useState<boolean | string>(false);
    const [ dialogContent ,setDialogContent ] = useState<OptionParams>({ kArea: '' , dArea: '' });
    const [ rows          ,setRows ] = useState<GridRowModel[]>([]);

    const [ onReload ,setOnReload ] = useState<boolean | null>(null);
    const [ loading  ,setLoading  ] = useState<boolean | null>(null);
    const [ loadingDialog  ,setLoadingDialog  ] = useState<boolean | null>(null);

    const ActionHeader:any = () => {
        return <IconButton onClick={()=>{ setOpenDialog('new'); 
                                          setDialogContent({  kArea: 'new' , dArea: '' }) }}>
                    <AddIcon addClass='font-16'/>
                </IconButton>
    }

    const ActionCell:any = ( params:any ) => {  
        return <IconButton onClick={()=>{ setOpenDialog( params.row.DAREA ); 
                                          setDialogContent({ kArea :  params.row.id 
                                                           , dArea :  params.row.DAREA }) }}>
                <EditIcon addClass='font-16'/>
            </IconButton>;
    }

    const ActiveCell:any = ( params:any ) => {
        return ( params.value
            ? <div className='flexed cursor-pointer' onClick={() => toggleArea(params.id, (params.value ? true : false))}><CheckBoxEmptyIcon addClass='font-22'/></div>
            : <div className='flexed cursor-pointer' onClick={() => toggleArea(params.id, (params.value ? true : false))}><CheckBoxFilledIcon addClass='font-22'/></div>
        )
    }

    const columns:GridColDef[] = [
        { field: 'DAREA'    , headerClassName: 'header-cell descrizioneArea', headerName: "Descrizione", type: 'text', width: 300, editable: false },
        { field: 'DATA_CANC', headerClassName: 'header-cell flagAbilitato'  , headerName: "Attiva"     , type: 'text', width: 60, editable: false, headerAlign: 'center', align: 'center',
            renderCell: (params:any) => ActiveCell(params), disableColumnMenu: true},
        { field: 'ACTION', renderHeader: ()=>ActionHeader(), headerClassName: 'header-cell', headerName: "", type: 'text', width: 60, align: 'center', headerAlign: 'center', editable: false, sortable: false,
            renderCell: (params:any) => ActionCell(params),disableColumnMenu: true}
    ];

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setDialogContent({});
    }

    const getAree = async () => {
        setLoading(true);

        try {    
            let axiosParams = {
                url		: 'aree',
                method	: 'get',
                baseURL	: config.API_URL,
                headers	: { Authorization: `Bearer ${auth.sessionInfo?.accessToken}` },
            }
            let response = await axios(axiosParams);

            if (response.status === 200){
                setRows([...response.data]);
            }
        } catch (e:any) {
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
            setLoading(false);
        }
    }

    const insArea = async (params: OptionParams) => {

		let axiosParams = {
			url		: 'area-ins',
			method	: 'post',
			baseURL	: config.API_URL,
			headers	: { Authorization: `Bearer ${auth.sessionInfo?.accessToken}` },
			data    : { ...params }
		}

		return await axios(axiosParams);
	}

  	const modArea = async (params: OptionParams) => {

		let axiosParams = {
			url		: 'area-mod',
			method	: 'post',
			baseURL	: config.API_URL,
			headers	: { Authorization: `Bearer ${auth.sessionInfo?.accessToken}` },
			data    : { ...params }
		}

		return await axios(axiosParams);
	}

    const submitArea = async () => {
        setLoadingDialog(true);
        try {
            let response: any = undefined;

            if ( openDialog === 'new' ) {
                response = await insArea(dialogContent);
            } else {
                response = await modArea(dialogContent);
            }

            let { nRetVal, errorMessage } = response.data;
            if ( response.status === 200 && nRetVal > 0 && !errorMessage ) {
                NotificationManager.success('Procedura Completata', '', 5000);
                handleCloseDialog();
                setOnReload(!onReload);
            } else {
                throw new Error(errorMessage);
            }
            setLoadingDialog(false);
        } catch (e: any) {
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
            setLoadingDialog(false);
        }
    }

    const toggleArea = async ( id:number, undo:boolean ) => {
        setLoading(true);
        try {
            let axiosParams = {
                url		: 'area-del',
                method	: 'post',
                baseURL	: config.API_URL,
                headers	: { Authorization: `Bearer ${auth.sessionInfo?.accessToken}` },
                data    : { kArea: id, undo }
            }
            let response = await axios(axiosParams);

            let { nRetVal, errorMessage } = response.data;
            if ( response.status === 200 && nRetVal > 0 && !errorMessage ) {
                NotificationManager.success('Procedura Completata', '', 5000);
                handleCloseDialog();
                setOnReload( !onReload );
            } else {
                throw new Error( errorMessage );
            }
        } catch (e: any) {
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        setPageName?.('Aree Talent');
        getAree();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if ( onReload !== null ) {
            getAree();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ onReload ]);

    useEffect(() => {
        if ( loading !== null) {
            setLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ rows ]);

    return (
        <div className='component-container aree'>
            { loading && ( <CircleProgress/>)}
            <div className='component-card'>
                <DataGridPremium
                    className            = 'dataGrid'
                    slots                = {{ noRowsOverlay: NoRowsDataGridComponent }}
                    initialState         = {{ pinnedColumns: { left: ['ACTION'], right: [] } }}
                    rows                 = { rows }
                    columns              = { columns }
                    rowHeight            = { 30 }
                    hideFooter           = { true }
                    disableColumnMenu={false}
                    disableColumnReorder={true}
                    disableAggregation={true}
                />
            </div>
            <Dialog open={ !!openDialog } onClose={ handleCloseDialog }>
                <DialogTitle className='dialogueTitleAreeContainer'>
                    { openDialog === 'new' ? 'Nuova Area' : openDialog }
                </DialogTitle>
                <DialogContent className='align-textifieldAree-center'>
                    {loadingDialog && <CircleProgress isDialog={true}/>}
                    <TextField
                        style    = {{ width: 300 }} 
                        id       = "standard-basic" 
                        label    = "Descrizione" 
                        variant  = "standard"
                        value    = { dialogContent.dArea }
                        onChange = { (event) => setDialogContent({ ...dialogContent, dArea: event.target.value }) } 
                    />
                </DialogContent>
                <DialogActions className='flex-box-between'>
                    <Button onClick={ handleCloseDialog } autoFocus>Annulla</Button>
                    <Button onClick={ submitArea } disabled={ dialogContent.dArea === '' } variant='contained'>Conferma</Button>
                </DialogActions>
		    </Dialog>
        </div>
    )
};

export default Aree;