import React, {useState, useEffect, useContext} from 'react';
import CircleProgress from '../../../../components/CircleProgress/CircleProgress';
import NoRowsDataGridComponent from '../../../../components/NoRowsDataGridComponent/NoRowsDataGridComponent';
import config from '../../../../config';
import './ReportFatturato.css';
import axios from 'axios';
import { AuthContext } from '../../../../contexts/authContext';
import { NotificationManager } from 'react-notifications';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { 
    DataGridPremium,
    LicenseInfo,
    GridColDef,
    GridRowModel,
    GridToolbarContainer,
    GridToolbarExport
} from '@mui/x-data-grid-premium';
import { AppAuthGlobalVariables } from '../../../../contexts/AppAuthContext';
import { formatNumbersIt, getFormatDate, getFormatDateFromDate, inputOnlyNumbers } from '../../../../utils/CommonUtilities';
import { CheckBoxEmptyIcon, CheckBoxFilledIcon, EditIcon } from '../../../../assets/icons/icons';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';

const months:{id:string, label:string}[] = [
    {id:'01', label:'Gennaio'},
    {id:'02', label:'Febbraio'},
    {id:'03', label:'Marzo'},
    {id:'04', label:'Aprile'},
    {id:'05', label:'Maggio'},
    {id:'06', label:'Giugno'},
    {id:'07', label:'Luglio'},
    {id:'08', label:'Agosto'},
    {id:'09', label:'Settembre'},
    {id:'10', label:'Ottobre'},
    {id:'11', label:'Novembre'},
    {id:'12', label:'Dicembre'},
]

function CustomToolbar() {
    const { pageName } = useContext(AppAuthGlobalVariables);
	return (
		<GridToolbarContainer>
			<GridToolbarExport
                excelOptions={{
                    fileName: `${pageName?.toLowerCase()} - ${getFormatDateFromDate(new Date(), '_')}`
                }}
            />
		</GridToolbarContainer>
	);
}

function ReportFatturato(props:any) {
    
    const auth = useContext(AuthContext);
    const { userEmail, changePageName } = useContext(AppAuthGlobalVariables);
    const urlParams = useParams();

    const navigate = useNavigate();

    const [ loading  , setLoading  ] = useState(true);
    const [rows, setRows] = useState<GridRowModel[]>([]);
    const [anno, setAnno] = useState(new Date().getFullYear().toString());
    const [beginPeriod, setBeginPeriod] = useState('01');
    const [endPeriod, setEndPeriod] = useState(((new Date().getMonth()+1)).toString().padStart(2, '0'));
    const [soggettiFatturazione, setSoggettiFatturazione] = useState<any>([]);
    const [chosenSoggFatt, setChosenSoggFatt] = useState<any>(null);
    const [radioFatturato, setRadioFatturato] = useState<any>('');
    
    const ActiveCell:any = ( params:any ) => {
        //per la riga 0 dei total
        if(params.id === 0){
            return null 
        }else{
            return ( !params.value || params.value === 'N'
                ? <div className='flexed' ><CheckBoxEmptyIcon addClass='font-22'/></div>
                : <div className='flexed' ><CheckBoxFilledIcon addClass='font-22'/></div>
            )
        }
    }
    const columns:GridColDef[] = [
        { field: 'DINTEST_FATT', headerClassName: 'header-cell soggettoFatturazioneDesc', headerName: "Soggetto Fatturazione", type: 'text', width: 300, editable: false},
        { field: 'DCLIENTE', headerClassName: 'header-cell clienteDesc', headerName: "Cliente", type: 'text', width: 270, editable: false},
        { field: 'DAGENZIA', headerClassName: 'header-cell agenziaDesc', headerName: "Agenzia", type: 'text', width: 270, editable: false},
        { field: 'KPROPOSTA', headerClassName: 'header-cell kProposta', headerName: "Id", type: 'text', width: 75, editable: false, align: 'center', headerAlign: 'center', disableColumnMenu: true},
        { field: 'DPROPOSTA', headerClassName: 'header-cell propostaDesc', headerName: "Proposta", type: 'text', width: 270, editable: false},
        { field: 'DATA_FATTURAZIONE', headerClassName: 'header-cell dataFattura', headerName: "Data Fattura", type: 'text', width: 100, editable: false, valueFormatter: (params) => getFormatDate(params.value)},
        { field: 'IMPORTO', headerClassName: 'header-cell importoFattura', headerName: "Importo Fattura", description: 'Proposte Contrattualizzate, Contrattualizzate in Modifica e Chiuse', type: 'text', width: 130, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
        { field: 'NOTE', headerClassName: 'header-cell note', headerName: "Note", type: 'text', width: 300, editable: false},
        { field: 'FLAG_FATTURATO', headerClassName: 'header-cell flagFatturato', headerName: "Fatturato", type: 'text', width: 75, editable: false, align: 'center', headerAlign: 'center', renderCell: (params:any) => ActiveCell(params),},
    ];

    useEffect(() => {
        changePageName?.(urlParams['*']);
        getSoggettiFatturazione();
    },[])

    useEffect(() => {
        async function init(){
            if(anno.length===4){
                await getReport();
            }
        }
        init();
    },[endPeriod, beginPeriod, anno, chosenSoggFatt, radioFatturato]);

    const getReport = async() => {
		
		try {
            let axiosParams = {
                url		: 'piani-fatturazione',
                method	: 'get',
                baseURL	: config.API_URL,
                headers	: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                params: { 
					beginPeriod: anno+beginPeriod,
                    endPeriod: anno+endPeriod,
                    kIntestFatt: chosenSoggFatt ? chosenSoggFatt.id : '',
                    typeIntestFatt: chosenSoggFatt ? chosenSoggFatt.TIPO : '',
                    flagFatturato: radioFatturato,
                    reportType: 'F'
				}
            }

            let response = await axios(axiosParams);

            if (response.status === 200){
				const rows = response.data
				//const data = rows.map((el:any,index:number) => {el.id = index; el.hierarchy = el.CLIENTE_CHILD ? [el.CLIENTE_PARENT, el.CLIENTE_CHILD] : [el.CLIENTE_PARENT]; return el})
	        	//setRows([...data]);
                setRows([...rows]);
				setLoading(false);
            }
        } catch (e:any) {
			setLoading(false);
            if(e.response && e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response && e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        }
	}

    const getSoggettiFatturazione = async() => {
        try {
            let axiosParams = {
                url		: 'sogg-fatt',
                method	: 'get',
                baseURL	: config.API_URL,
                headers	: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                params: {}
            }

            let response = await axios(axiosParams);

            if (response.status === 200){
				const rows = response.data
                setSoggettiFatturazione([...rows]);
				setLoading(false);
            }
        } catch (e:any) {
			setLoading(false);
            if(e.response && e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response && e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        }
    }

    return (
        <div className='component-container report-fatturato'>
            { loading && ( <CircleProgress/>)}
            <div className='component-card'>
                <div className='report-wrapper'>
                    <div className='filters-section'>
                        <TextField 
                            id="anno" 
                            label="Anno" 
                            variant="standard"
                            value={anno}
                            onChange={(event) => {if(inputOnlyNumbers(event.target.value))setAnno(event.target.value)}} 
                            inputProps={{maxLength: 4}}
                            error={anno.length!==4}
                            sx={{maxWidth:'80px'}}
                        />
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="beginPeriodLabel">Da</InputLabel>
                            <Select
                                labelId="beginPeriodLabel"
                                id="beginPeriod"
                                value={beginPeriod}
                                onChange={(event ) => setBeginPeriod(event.target.value)}
                                label="Da"
                            >
                                {months.map((mese:any) => <MenuItem key={mese.id} value={mese.id}>{mese.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="endPeriodLabel">A</InputLabel>
                            <Select
                                labelId="endPeriodLabel"
                                id="endPeriod"
                                value={endPeriod}
                                onChange={(event ) => setEndPeriod(event.target.value)}
                                label="A"
                            >
                                {months.map((mese:any) => <MenuItem key={mese.id} value={mese.id}>{mese.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <Autocomplete
                            multiple={false}
                            id="autocomplete-soggetto-fatturazione"
                            options={soggettiFatturazione}
                            value={chosenSoggFatt}
                            clearOnBlur={false}
                            onChange={(event, newValue) => {
                                setChosenSoggFatt(newValue);
                            }}
                            groupBy = { (option: any) => {return option.TYPE}}
                            renderInput={(params) => <TextField {...params} label="Soggetto di Fatturazione" className='sogg-fatt-field' variant='standard' style={{minWidth: '300px'}}/>}
                        />
                        <FormControl>
                            {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                            <RadioGroup
                                row
                                aria-labelledby="radio-buttons-fatturato"
                                name="radio-buttons-fatturato"
                                onChange={(event) => setRadioFatturato(event?.target.value)}
                                value={radioFatturato}
                            >
                                <FormControlLabel value="" control={<Radio size="small" />} label="Tutte" />
                                <FormControlLabel value="Y" control={<Radio size="small" />} label="Fatturate" />
                                <FormControlLabel value="N" control={<Radio size="small" />} label="Non fatturate" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <DataGridPremium
                        className='dataGrid'
                        slots={{
                            noRowsOverlay: NoRowsDataGridComponent,
                            toolbar: CustomToolbar
                        }}
                        initialState={{ pinnedColumns: { right: ['FLAG_CONFERMA_ATT', 'FLAG_FATTURATO'] } }}
                        rows={rows}
                        columns={columns}
                        rowHeight={30}
                        hideFooter={true}
                        isCellEditable={(params) => params.id!==0}
                        disableColumnMenu={false}
                        disableColumnReorder={true}
                        disableAggregation={true}
                        disableColumnFilter={true}
						disableRowGrouping={true}
                        getRowClassName={(params) => params.row.KSTATO === '06' ? 'red-row' : !params.id ? 'non-editable-row' : ''}
                        pinnedRows={{bottom: [], top: [rows.length > 0 ? rows.find((row:any) => row.id===0) : {id:0}]}}
                    />
                </div>
            </div>
        </div>
    )
}

export default ReportFatturato;