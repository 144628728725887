import React, {useContext, useState, useEffect} from 'react';
//import { useParams } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import NoRowsDataGridComponent           from '../../../../components/NoRowsDataGridComponent/NoRowsDataGridComponent.tsx';
import { Button
        ,Dialog
        ,DialogActions
        ,DialogTitle
        ,DialogContent
        ,IconButton
        ,TextField } from '@mui/material';
import axios         from 'axios';
import config                     from '../../../../config.ts';
import CircleProgress             from '../../../../components/CircleProgress/CircleProgress';
import { AuthContext }            from '../../../../contexts/authContext.tsx';
import { AppAuthGlobalVariables } from '../../../../contexts/AppAuthContext';
import { OptionParams
        ,ObjTextFieldParams }     from '../../../../utils/ConfigInterfaces'
import { AddIcon
       , CheckBoxEmptyIcon
       , CheckBoxFilledIcon
       , EditIcon } from '../../../../assets/icons/icons';
import './RefProgetto.css';

import { DataGridPremium,
	LicenseInfo,
	GridColDef,
	GridRowModel,
    GridToolbarContainer,
    GridToolbarExport
	} from '@mui/x-data-grid-premium';
import { getFormatDateFromDate } from '../../../../utils/CommonUtilities.ts';

LicenseInfo.setLicenseKey(
    'a5fb85400407876bcf9c1364b93581a5Tz0xMDEzNzksRT0xNzYyNTM4ODk3MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y',
);


function RefProgetto(props:any) {

    const auth = useContext(AuthContext)
    //const urlParams = useParams();
    const { setPageName } = useContext(AppAuthGlobalVariables);

    const [ openDialog    ,setOpenDialog    ] = useState<boolean | string>(false);
    const [ dialogContent ,setDialogContent ] = useState<OptionParams>({ kRefProg: '' ,dRPCognome: '' ,dRPNome: '' ,dRPEmail: '' });
    const [ rows     ,setRows ] = useState<GridRowModel[]>([]);

    const [ onReload ,setOnReload ] = useState<boolean | null>(null);
    const [ loading  ,setLoading  ] = useState<boolean | null>(null);
    const [ loadingDialog  ,setLoadingDialog  ] = useState<boolean | null>(null);

    const ActionHeader:any = () => {
        return <IconButton onClick={()=>{ setOpenDialog('new'); 
                                          setDialogContent({ kRefProg: 'new' ,dRPCognome: '' ,dRPNome: '' ,dRPEmail: '' }) }}>
                    <AddIcon addClass='font-16'/>
                </IconButton>
    };

    const ActionCell:any = ( params:any ) => {
        return <IconButton onClick={()=>{ setOpenDialog( params.row.COGNOME + ' ' + params.row.NOME ); 
                         setDialogContent({ kRefProg   : params.row.id
                                           ,dRPCognome : params.row.COGNOME
                                           ,dRPNome    : params.row.NOME 
                                           ,dRPEmail   : params.row.E_MAIL }) }}>
                    <EditIcon addClass='font-16'/>
                </IconButton>;
    }

    const ActiveCell:any = ( params:any ) => {
        return ( params.value
            ? <div className='flexed cursor-pointer' onClick={() => toggleActive(params.id, (params.value ? true : false))}><CheckBoxEmptyIcon addClass='font-22'/></div>
            : <div className='flexed cursor-pointer' onClick={() => toggleActive(params.id, (params.value ? true : false))}><CheckBoxFilledIcon addClass='font-22'/></div>
        )
    }

    const columns:GridColDef[] = [
        { field: 'COGNOME'  , headerClassName: 'header-cell cognome'      , headerName: "Cognome", type: 'text', width: 200, editable: false},
        { field: 'NOME'     , headerClassName: 'header-cell nome'         , headerName: "Nome"   , type: 'text', width: 200, editable: false},
        { field: 'E_MAIL'   , headerClassName: 'header-cell email'        , headerName: "Email"  , type: 'text', width: 300, editable: false},
        { field: 'DATA_CANC', headerClassName: 'header-cell flagAbilitato', headerName: "Attivo" , type: 'text', width: 60, editable: false, headerAlign: 'center', align: 'center',
            renderCell: (params:any) => ActiveCell(params),
            disableColumnMenu: true,
            disableExport: true},
        { field: 'ACTION', renderHeader: ()=>ActionHeader(), headerClassName: 'header-cell', headerName: "", type: 'text', width: 60, align: 'center', headerAlign: 'center', editable: false, sortable: false,
            renderCell: (params:any) => ActionCell(params),
            disableColumnMenu: true,
            disableExport: true
        }
    ];

    function CustomToolbar() {
        const { pageName } = useContext(AppAuthGlobalVariables);
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    excelOptions={{
                        fileName: `${pageName?.toLowerCase()} - ${getFormatDateFromDate(new Date(), '_')}`
                    }}
                />
            </GridToolbarContainer>
        );
    }

    const makeObjTextField = ( params: ObjTextFieldParams ) => {
        let { sKey, label, obj, value, set_value, style, disabled } = params;
        if (sKey) { 
            return <TextField
                key      = { sKey }
                id       = { sKey }
                label    = { label || "Descrizione" }
                value    = { value }
                onChange = { (event) => { set_value({ ...obj, [sKey]: event.target.value }) } }
                style    = { style || { width: 300 }} 
                variant  = "standard"
                disabled = { disabled || false } // In Tutti i casi controllo se il campo è enable, nel caso di agente_cod devo verificare se l'utente è admin
            />
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setDialogContent({});
    }

    const getRefProgetto = async() => {
        setLoading(true);

        try {    
            let axiosParams = {
                url		: 'refs-progetto',
                method	: 'get',
                baseURL	: config.API_URL,
                headers	: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
            }
            let response = await axios(axiosParams);

            if (response.status === 200){
                setRows(response.data)
            }
        } catch (e:any) {
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
            setLoading(false);
        }
    }
    
    const insRefProgetto = async (params: OptionParams) => {

		let axiosParams = {
			url		: 'ref-progetto-ins',
			method	: 'post',
			baseURL	: config.API_URL,
			headers	: { Authorization: `Bearer ${auth.sessionInfo?.accessToken}` },
			data    : { ...params, impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : '' }
		}
		return await axios(axiosParams);
	}

  	const modRefProgetto = async (params: OptionParams) => {

		let axiosParams = {
			url		: 'ref-progetto-mod',
			method	: 'post',
			baseURL	: config.API_URL,
			headers	: { Authorization: `Bearer ${auth.sessionInfo?.accessToken}` },
			data    : { ...params, impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : '' }
		}
		return await axios(axiosParams);
	}

    const submitRefProgetto = async () => {
        setLoadingDialog(true);
        try {
            let response: any = undefined;
            
            if ( openDialog === 'new' ) {
                response = await insRefProgetto(dialogContent);
            } else {
                response = await modRefProgetto(dialogContent);
            }
    
            let { nRetVal, errorMessage } = response.data;
            if ( response.status === 200 && nRetVal > 0 && !errorMessage ) {
                NotificationManager.success('Procedura Completata', '', 5000);
                handleCloseDialog();
                setOnReload(!onReload);
            } else {
                throw new Error(errorMessage);
            }
            setLoadingDialog(false);
        } catch (e: any) {
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
            setLoadingDialog(false);
        }
    }

    const toggleActive = async ( id:number, undo:boolean ) => {
        setLoading(true);
        try {
            let axiosParams = {
                url		: 'ref-progetto-del',
                method	: 'post',
                baseURL	: config.API_URL,
                headers	: { Authorization: `Bearer ${auth.sessionInfo?.accessToken}` },
                data    : { kRefProg: id, undo, impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : '' }
            }
            let response = await axios(axiosParams);

            let { nRetVal, errorMessage } = response.data;
            if ( response.status === 200 && nRetVal > 0 && !errorMessage ) {
                NotificationManager.success( 'Procedura Completata' , '', 5000);
                handleCloseDialog();
                setOnReload( !onReload );
            } else {
                throw new Error( errorMessage );
            }
        } catch (e: any) {
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        setPageName?.('Referenti Progetto');
        getRefProgetto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        if ( onReload !== null ) {
            getRefProgetto();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ onReload ]);

    useEffect(() => {
        if ( loading !== null) {
            setLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ rows ]);

    return (
        <div className='component-container talent'>
            { loading && (<CircleProgress/>)}
            <div className='component-card'>
                <DataGridPremium
                    className            = 'dataGrid'
                    slots                = {{ noRowsOverlay: NoRowsDataGridComponent, toolbar: CustomToolbar }}
                    initialState         = {{ pinnedColumns: { left: ['ACTION'], right: [] } }}
                    rows                 = { rows }
                    columns              = { columns }
                    rowHeight            = { 30 }
                    hideFooter           = { true }
                    disableColumnMenu={false}
                    disableColumnReorder={true}
                    disableAggregation={true}
                />
            </div>
            <Dialog open={ !!openDialog } onClose={ handleCloseDialog }>
                <DialogTitle className='dialogueTitleAreeContainer'>
                    { openDialog === 'new' ? 'Nuovo Referente Progetto Esterno' : openDialog }
                </DialogTitle>
                <DialogContent className='align-textifieldAree-center'>
                    {loadingDialog && <CircleProgress isDialog={true}/>}
                    { makeObjTextField({ sKey: 'dRPNome'   , label: 'Nome'   , obj: dialogContent, value: dialogContent.dRPNome   , set_value: setDialogContent }) }
                    { makeObjTextField({ sKey: 'dRPCognome', label: 'Cognome', obj: dialogContent, value: dialogContent.dRPCognome, set_value: setDialogContent }) }
                    { makeObjTextField({ sKey: 'dRPEmail'  , label: 'Email'  , obj: dialogContent, value: dialogContent.dRPEmail  , set_value: setDialogContent }) }
                </DialogContent>
                <DialogActions className='flex-box-between'>
                    <Button onClick={ handleCloseDialog } autoFocus>Annulla</Button>
                    <Button onClick={ submitRefProgetto } disabled={ dialogContent.dRPNome === "" || dialogContent.dRPCognome === "" || dialogContent.dRPEmail === "" } variant='contained'>Conferma</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
};

export default RefProgetto;