import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import config from '../../../../config';
import { AuthContext } from '../../../../contexts/authContext';
import { NotificationManager } from 'react-notifications';
import { AppAuthGlobalVariables } from '../../../../contexts/AppAuthContext';
import NoRowsDataGridComponent from '../../../../components/NoRowsDataGridComponent/NoRowsDataGridComponent';
import './ReportAndamento.css';
import { 
    DataGridPremium,
    LicenseInfo,
    GridColDef,
    GridRowModel,
	GridToolbarContainer,
	GridToolbarExport,
} from '@mui/x-data-grid-premium';
import { formatNumbersIt, getFormatDateFromDate, inputOnlyNumbers } from '../../../../utils/CommonUtilities';
import CircleProgress from '../../../../components/CircleProgress/CircleProgress';
import { Checkbox
        ,FormControlLabel
        ,FormGroup
        ,TextField } from '@mui/material';

const columns:GridColDef[] = [
    { field: 'ETICHETTA', headerClassName: 'header-cell client', headerName: "", type: 'text', width: 120, editable: false, sortable: false, disableColumnMenu: true, valueFormatter: (params:any) => {if(params.id > 9){return new Date(params.value + ' 0').toLocaleString('it-IT', {month: "long"}).toUpperCase()}else{return params.value}}},
    { field: 'RICAVI_ACT', headerClassName: 'header-cell ricActual', headerName: "ACT Ricavi", type: 'text', width: 130, editable: false, sortable: false, description: 'Proposte Confermate, Contrattualizzate, Contrattualizzate in Modifica e Chiuse su Data Cambio Stato', align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + (params.id!==8 ? ' €' : '') : (params.id!==8 ? '0 €' : '0')}},
    { field: 'RICAVI_PY', headerClassName: 'header-cell ricPy', headerName: "AP Ricavi", type: 'text', width: 130, editable: false, sortable: false, description: 'Anno Precedente: Proposte Confermate, Contrattualizzate, Contrattualizzate in Modifica e Chiuse su Data Cambio Stato', align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + (params.id!==8 ? ' €' : '') : (params.id!==8 ? '0 €' : '0')}},
    { field: 'RICAVI_DELTA_PY', headerClassName: 'header-cell ricDeltaPy', headerName: "Δ Anno Prec.", type: 'text', width: 100, editable: false, sortable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' %' : '0 %'}},
    { field: 'FATTURATO_FIN_ACT', headerClassName: 'header-cell fattActual', headerName: "ACT FIN", type: 'text', width: 130, editable: false, sortable: false, description: 'Proposte Contrattualizzate, Contrattualizzate in Modifica e Chiuse su Data Fatturazione', align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + (params.id!==8 ? ' €' : '') : (params.id!==8 ? '0 €' : '0')}},
    { field: 'FATTURATO_FIN_PY', headerClassName: 'header-cell fattPy', headerName: "AP FIN", type: 'text', width: 130, editable: false, sortable: false, description: 'Anno Precedente: Proposte Contrattualizzate, Contrattualizzate in Modifica e Chiuse su Data Fatturazione', align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + (params.id!==8 ? ' €' : '') : (params.id!==8 ? '0 €' : '0')}},
    { field: 'FATTURATO_DELTA_PY', headerClassName: 'header-cell fattDeltaPy', headerName: "Δ Anno Prec.", type: 'text', width: 100, editable: false, sortable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' %' : '0 %'}},
    { field: 'DOOM_MARG_FIN_ACT', headerClassName: 'header-cell margActual', headerName: "ACT Margini", type: 'text', width: 130, editable: false, sortable: false, description: 'Proposte Contrattualizzate, Contrattualizzate in Modifica e Chiuse su Data Proposta', align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + (params.id!==8 ? ' €' : '') : (params.id!==8 ? '0 €' : '0')}},
    { field: 'DOOM_MARG_FIN_PY', headerClassName: 'header-cell margPy', headerName: "AP Margini", type: 'text', width: 130, editable: false, sortable: false, description: 'Anno Precedente: Proposte Contrattualizzate, Contrattualizzate in Modifica e Chiuse su Data Proposta', align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + (params.id!==8 ? ' €' : '') : (params.id!==8 ? '0 €' : '0')}},
    { field: 'DOOM_MARG_DELTA_PY', headerClassName: 'header-cell margDeltaPy', headerName: "Δ Anno Prec.", type: 'text', width: 100, editable: false, sortable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' %' : '0 %'}},
    { field: 'TOT_COSTI_FIN_ACT', headerClassName: 'header-cell costiActual', headerName: "ACT Costi", type: 'text', width: 130, editable: false, sortable: false, description: 'Proposte Contrattualizzate, Contrattualizzate in Modifica e Chiuse su Data Proposta', align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + (params.id!==8 ? ' €' : '') : (params.id!==8 ? '0 €' : '0')}},
    { field: 'TOT_COSTI_FIN_PY', headerClassName: 'header-cell costiPy', headerName: "AP Costi", type: 'text', width: 130, editable: false, sortable: false, description: 'Anno Precedente: Proposte Contrattualizzate, Contrattualizzate in Modifica e Chiuse su Data Proposta', align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + (params.id!==8 ? ' €' : '') : (params.id!==8 ? '0 €' : '0')}},
    { field: 'TOT_COSTI_DELTA_PY', headerClassName: 'header-cell costiDeltaPy', headerName: "Δ Anno Prec.", type: 'text', width: 100, editable: false, sortable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' %' : '0 %'}},
    { field: 'DN_FIN_ACT', headerClassName: 'header-cell dnActual', headerName: "ACT DN", type: 'text', width: 130, editable: false, sortable: false, description: 'Proposte Contrattualizzate, Contrattualizzate in Modifica e Chiuse su Data Proposta', align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + (params.id!==8 ? ' €' : '') : (params.id!==8 ? '0 €' : '0')}},
    { field: 'DN_FIN_PY', headerClassName: 'header-cell dnPy', headerName: "AP DN", type: 'text', width: 130, editable: false, sortable: false, description: 'Anno Precedente: Proposte Contrattualizzate, Contrattualizzate in Modifica e Chiuse su Data Proposta', align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + (params.id!==8 ? ' €' : '') : (params.id!==8 ? '0 €' : '0')}},
    { field: 'DN_DELTA_PY', headerClassName: 'header-cell dnDeltaPy', headerName: "Δ Anno Prec.", type: 'text', width: 100, editable: false, sortable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' %' : '0 %'}},
];

function CustomToolbar() {
    const { pageName } = useContext(AppAuthGlobalVariables);
	return (
		<GridToolbarContainer>
			<GridToolbarExport
                excelOptions={{
                    fileName: `${pageName?.toLowerCase()} - ${getFormatDateFromDate(new Date(), '_')}`
                }}
            />
		</GridToolbarContainer>
	);
}

function ReportAndamento(props:any) {
    
    const auth = useContext(AuthContext);
	const {userEmail, setPageName} = useContext(AppAuthGlobalVariables);

	const [loading, setLoading] = useState<Boolean>(true);
	const [rows, setRows] = useState<GridRowModel[]>([]);
    const [anno, setAnno] = useState(new Date().getFullYear().toString());

    const [ ricavi, setRicavi ] = useState<boolean>(true)
    const [ fatturato, setFatturato ] = useState<boolean>(true);
    const [ margini, setMargini ] = useState<boolean>(true);
    const [ costi, setCosti ] = useState<boolean>(true);
    const [ dn, setDn ] = useState<boolean>(true);
  	
    const hiddenCols = {
        RICAVI_ACT: ricavi,
        RICAVI_PY: ricavi,
        RICAVI_DELTA_PY: ricavi,
        FATTURATO_FIN_ACT: fatturato,
        FATTURATO_FIN_PY: fatturato,
        FATTURATO_DELTA_PY: fatturato,
        DOOM_MARG_FIN_ACT: margini,
        DOOM_MARG_FIN_PY: margini,
        DOOM_MARG_DELTA_PY: margini,
        TOT_COSTI_FIN_ACT: costi,
        TOT_COSTI_FIN_PY: costi,
        TOT_COSTI_DELTA_PY: costi,
        DN_FIN_ACT: dn,
        DN_FIN_PY: dn,
        DN_DELTA_PY: dn
    }

    useEffect(() => {
        async function init(){
            setPageName?.('Report - Andamento');
            await getReport();
        }
        init();
    },[]);

    useEffect(() => {
        async function init() {
            if(anno.length===4){
                await getReport();
            }
        }
        init();
    },[ anno ]);

    const getReport = async() => {
		
		try {
            let axiosParams = {
                url		: 'report-andamento',
                method	: 'get',
                baseURL	: config.API_URL,
                headers	: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                params  : {
                    anno
                }
            }

            let response = await axios(axiosParams);

            if (response.status === 200){
				const rows = response.data;
	        	setRows(rows);

				setLoading(false);
            }
        } catch (e:any) {
			setLoading(false);
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        }
	}

    return (
        <div className='component-container report-andamento'>
			{loading && (<CircleProgress/>)}
			<div className='component-card'>
				<div className='report-wrapper'>
                    <div className='filters-section'>
                        <FormGroup className='wrapper-checks'>
                            <TextField 
                                id="anno" 
                                label="Anno" 
                                variant="standard"
                                value={anno}
                                onChange={(event) => {if(inputOnlyNumbers(event.target.value))setAnno(event.target.value)}} 
                                inputProps={{maxLength: 4}}
                                sx={{maxWidth:'80px'}}
                                className='input-anno'
                            />
                            <FormControlLabel control={<Checkbox onChange={() => setRicavi(!ricavi)} checked={ricavi} />} label="Ricavi" />
                            <FormControlLabel control={<Checkbox onChange={() => setFatturato(!fatturato)} checked={fatturato} />} label="Fatturato" />
                            <FormControlLabel control={<Checkbox onChange={() => setMargini(!margini)} checked={margini} />} label="Margini" />
                            <FormControlLabel control={<Checkbox onChange={() => setCosti(!costi)} checked={costi} />} label="Costi" />
                            <FormControlLabel control={<Checkbox onChange={() => setDn(!dn)} checked={dn} />} label="DN" />
                        </FormGroup>
                    </div>
                    <DataGridPremium
                        className='dataGrid'
                        slots={{
                            noRowsOverlay: NoRowsDataGridComponent,
                            toolbar: CustomToolbar,
                        }}
                        rows={rows}
                        columns={columns}
                        rowHeight={30}
                        hideFooter={true}
                        disableColumnMenu={false}
                        disableColumnReorder={true}
                        disableAggregation={true}
                        disableColumnFilter={true}
                        disableRowGrouping={true}
                        getRowClassName={(params) => params.row.KSTATO === '06' ? 'red-row' : !params.id ? 'non-editable-row' : ''}
                        pinnedColumns={{left: ["ETICHETTA"]}}
                        columnVisibilityModel={hiddenCols}
                    />
                </div>
            </div>
        </div>
    )
}

export default ReportAndamento