import React from 'react';
import TextField from '@mui/material/TextField';
import { getFormatDate } from '../../../../../utils/CommonUtilities';

function Logs(props:any) {
	return (
		<div className='proposta-logs'>
			<div className='logs-wrapper'>
				<div className='flex-row'>
					<TextField
						id                   = "data-ultima-modifica"
						label                = "Data Ultima Modifica"
						variant              = "standard"
						value                = {props.lastEditDate}
						inputProps           = {{maxLength: 50}}
						disabled
					/>
					<TextField
						id                   = "user-ultima-mod"
						label                = "Ultima Modifica Effettuata Da"
						variant              = "standard"
						value                = {props.lastModMadeBy}
						inputProps           = {{maxLength: 50}}
						disabled
					/>
					<TextField
						id                   = "user-ins"
						label                = "Creata Da"
						variant              = "standard"
						value                = {props.createdBy}
						inputProps           = {{maxLength: 50}}
						disabled
					/>
				</div>
				<div className='flex-row'>
					<TextField
						id                   = "lead-to-conf-date"
						label                = "Data Lead -> Confermata"
						variant              = "standard"
						value                = {props.dateLeadToConf ? getFormatDate(props.dateLeadToConf) : props.dateLeadToConf}
						inputProps           = {{maxLength: 50}}
						disabled
					/>
					<TextField
						id                   = "conf-to-contr-date"
						label                = "Data Confermata -> Contrattualizzata"
						variant              = "standard"
						value                = {props.dateConfToContr ? getFormatDate(props.dateConfToContr) : props.dateConfToContr}
						inputProps           = {{maxLength: 50}}
						disabled
					/>
					<TextField
						id                   = "contr-to-chiuso-date"
						label                = "Data Contrattualizzata -> Chiusa"
						variant              = "standard"
						value                = {props.dateContrToClose ? getFormatDate(props.dateContrToClose) : props.dateContrToClose}
						inputProps           = {{maxLength: 50}}
						disabled
					/>
				</div>
			</div>
		</div>
	)
}

export default Logs