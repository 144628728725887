import React, {useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import './ReportTalents.css';
import CircleProgress from '../../../../components/CircleProgress/CircleProgress';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { 
    DataGridPremium,
    GridColDef,
    GridRowModel,
	GridToolbarContainer,
	GridToolbarExport,
} from '@mui/x-data-grid-premium';
import NoRowsDataGridComponent from '../../../../components/NoRowsDataGridComponent/NoRowsDataGridComponent';
import axios from 'axios';
import config from '../../../../config';
import { AuthContext } from '../../../../contexts/authContext';
import { NotificationManager } from 'react-notifications';
import { formatNumbersIt, getFormatDateFromDate, inputOnlyNumbers } from '../../../../utils/CommonUtilities';
import { AppAuthGlobalVariables } from '../../../../contexts/AppAuthContext';

function CustomToolbar() {
    const { pageName } = useContext(AppAuthGlobalVariables);
	return (
		<GridToolbarContainer>
			<GridToolbarExport
                excelOptions={{
                    fileName: `${pageName?.toLowerCase()} - ${getFormatDateFromDate(new Date(), '_')}`
                }}
            />
		</GridToolbarContainer>
	);
}

const columns:GridColDef[] = [
	{ field: 'TALENT', headerClassName: 'header-cell talentDesc', headerName: "Talent", type: 'text', width: 200, editable: false},

	{ field: 'ACTUAL', headerClassName: 'header-cell actual', headerName: "Actual", type: 'text', width: 150, editable: false, description: 'Proposte Confermate, Contrattualizzate, Contrattualizzate in Modifica e Chiuse', align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'LEADS' , headerClassName: 'header-cell leads' , headerName: "Leads" , type: 'text', width: 150, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
// { field: 'CONFERMATE', headerClassName: 'header-cell confermate', headerName: "Confermate", type: 'text', width: 150, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'RIFIUTATE_CA' , headerClassName: 'header-cell rifiutate_ca' , headerName: "Rifiutate CL/AG" , type: 'text', width: 150, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'RIFIUTATE_TAL', headerClassName: 'header-cell rifiutate_tal', headerName: "Rifiutate Talent", type: 'text', width: 150, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'TOTALE_PROPOSTE', headerClassName: 'header-cell totProposta', headerName: "Totale Proposte", type: 'text', width: 150, editable: false, description: 'Tutti gli stati', align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},

	{ field: 'TARGET', headerClassName: 'header-cell target', headerName: "Target", type: 'text', width: 150, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'DELTA_TARGET', headerClassName: 'header-cell deltaTargetFin', headerName: "Actual vs. Target"   , type: 'text', width: 120, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' %' : '0 %'}},
	{ field: 'GEN' , headerClassName: 'header-cell gen', headerName: "Gennaio"  , type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'FEB' , headerClassName: 'header-cell feb', headerName: "Febbraio" , type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'MAR' , headerClassName: 'header-cell mar', headerName: "Marzo"    , type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'APR' , headerClassName: 'header-cell apr', headerName: "Aprile"   , type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'MAG' , headerClassName: 'header-cell mag', headerName: "Maggio"   , type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'GIU' , headerClassName: 'header-cell giu', headerName: "Giugno"   , type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'LUG' , headerClassName: 'header-cell lug', headerName: "Luglio"   , type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'AGO' , headerClassName: 'header-cell ago', headerName: "Agosto"   , type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'SETT', headerClassName: 'header-cell set', headerName: "Settembre", type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'OTT' , headerClassName: 'header-cell ott', headerName: "Ottobre"  , type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'NOV' , headerClassName: 'header-cell nov', headerName: "Novembre" , type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'DIC' , headerClassName: 'header-cell dic', headerName: "Dicembre" , type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'ACTUAL_FIN'  , headerClassName: 'header-cell actualFin'     , headerName: "ACT FIN" 			  , type: 'text', width: 150, editable: false, description: 'Proposte Contrattualizzate, Contrattualizzate in Modifica e Chiuse', align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'PY_FIN'      , headerClassName: 'header-cell pyFin'         , headerName: "PY FIN"  			  , type: 'text', width: 150, editable: false, description: 'Anno Precedente: Proposte Contrattualizzate, Contrattualizzate in Modifica e Chiuse', align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
	{ field: 'DELTA_PY_FIN', headerClassName: 'header-cell deltaPyFin'    , headerName: "Δ ACT FIN vs. PY FIN", type: 'text', width: 120, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' %' : '0 %'}},
];

function ReportTalents(props:any) {
	const navigate = useNavigate();
  	const auth = useContext(AuthContext);
	const {userEmail,setPageName} = useContext(AppAuthGlobalVariables)
	const urlQueryParam = window.location.hash.split('=')[1];
	const [loading, setLoading] = useState(true);
	const [rows, setRows] = useState<GridRowModel[]>([]);
    const [talentManagers, setTalentManagers] = useState([]);
	const [referentiCommerciali, setReferentiCommerciali] = useState([]);
    // const [anno, setAnno] = useState(urlQueryParam || new Date().getFullYear().toString());
    // const [kTalentManager, setKTalentManager] = useState<any>('');
	// const [kRefCommerciale, setKRefCommerciale] = useState<any>('');

	useEffect(() => {
        async function init(){
			await getUtenti();
			setPageName?.('Report - Talent');
        }
        init();
    },[]);

	useEffect(() => {
		async function init() {
			if(talentManagers?.length!==0){
				if(props?.annoRepTal?.length===4){
					await getReport();
				} else {
					setLoading(false);
				}
			} 
		}
		init();
	},[props?.annoRepTal, props?.kTalentManagerRepTal, props?.kRefCommercialeRepTal, talentManagers]);

	const getUtenti = async() => {
        try {    
			let axiosParams = {
				url		: 'users',
				method	: 'get',
				baseURL	: config.API_URL,
				headers	: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`}
			}
			let response = await axios(axiosParams);

			if (response.status === 200){
                let talentManagers = response.data.filter((tm:any) => tm.FLAG_TALENT_MANAGER === 'Y');
				let refCommerciali = response.data.filter((tm:any) => tm.FLAG_REF_COMM === 'Y');
				setTalentManagers(talentManagers);
				setReferentiCommerciali(refCommerciali);
			}

		} catch (e:any) {
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
    }

	const getReport = async() => {
		try {
            let axiosParams = {
                url		: 'report-fatturato-talent',
                method	: 'get',
                baseURL	: config.API_URL,
                headers	: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                params: { 
					anno: 					props.annoRepTal,
					kTalentManager: 		props.kTalentManagerRepTal,
					kReferenteCommerciale:  props.kRefCommercialeRepTal
				}
            }

            let response = await axios(axiosParams);

            if (response.status === 200){
				const rows = response.data
				const data = rows.map((el:any,index:number) => {el.id = index; return el})
	        	setRows([...data]);

				setLoading(false);
            }
        } catch (e:any) {
			setLoading(false);
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        }
	}
	
	return (
		<div className='component-container report-talents'>
			{loading && (<CircleProgress/>)}
			<div className='component-card'>
				<div className='report-wrapper'>
					<div className='filters-section'>
						<TextField 
							id="anno" 
							label="Anno" 
							variant="standard"
							value={props.annoRepTal}
							onChange={(event) => {if(inputOnlyNumbers(event.target.value))props.setAnnoRepTal(event.target.value)}} 
							inputProps={{maxLength: 4}}
							sx={{maxWidth:'80px'}}
						/>
						<FormControl variant="standard" sx={{ m: 1, maxWidth: 300, width: '100%' }}>
							<InputLabel id="talentManagerLabel">Talent Manager</InputLabel>
							<Select
								labelId="talentManagerLabel"
								id="talentManager"
								value={props.kTalentManagerRepTal}
								onChange={(event ) => props.setKTalentManagerRepTal(event.target.value)}
								label="Talent Manager"
							>
								<MenuItem key={'0'} value={''}>Tutti</MenuItem>
								{talentManagers.map((tm:any) => <MenuItem key={tm.id} value={tm.id}>{tm.NOME} {tm.COGNOME}</MenuItem>)}
							</Select>
						</FormControl>
						<FormControl variant="standard" sx={{ m: 1, maxWidth: 300, width: '100%' }}>
							<InputLabel id="refCommercialeLabel">Referente Commerciale</InputLabel>
							<Select
								labelId="refCommercialeLabel"
								id="refCommerciale"
								value={props.kRefCommercialeRepTal}
								onChange={(event ) => props.setKRefCommercialeRepTal(event.target.value)}
								label="Referente Commerciale"
							>
								<MenuItem key={'0'} value={''}>Tutti</MenuItem>
								{referentiCommerciali.map((tm:any) => <MenuItem key={tm.id} value={tm.id}>{tm.NOME} {tm.COGNOME}</MenuItem>)}
							</Select>
						</FormControl>
					</div>
					<DataGridPremium
						className='dataGrid'
						slots={{
							noRowsOverlay: NoRowsDataGridComponent,
							toolbar: CustomToolbar,
						}}
						rows={rows}
						columns={columns}
						rowHeight={30}
						hideFooter={true}
						isCellEditable={(params) => params.id!==0}
						disableColumnMenu={false}
						disableColumnReorder={true}
						disableAggregation={true}
						disableColumnFilter={true}
						disableRowGrouping={true}
						getCellClassName={(params:any) => params.field==='TALENT' && params.id > 0 ? 'cursor-pointer bg-clickable' : ''}
						onCellClick={(params:any) => {if(params.field==='TALENT' && params.id > 0){props.setSelectedPage('report-talents-dettaglio');navigate(`/report/talents/dettaglio/${params.row.KTALENT}`)}}}
						getRowClassName={(params) => params.row.KSTATO === '06' ? 'red-row' : !params.id ? 'non-editable-row' : ''}
						pinnedRows={{bottom: [], top: [rows.length > 0 ? rows[0] : {id:0}]}}
					/>
				</div>
			</div>
		</div>
	)
}

export default ReportTalents