import React, { useState, useEffect, useContext } from 'react'
import { NotificationManager } from 'react-notifications';
import { useParams, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import axios  		   from 'axios';
import config 		   from '../../../../config';
import CircleProgress  from '../../../../components/CircleProgress/CircleProgress';
import { AuthContext } from '../../../../contexts/authContext';
import { AppAuthGlobalVariables } from '../../../../contexts/AppAuthContext';
import { AddIcon
       , CheckIcon
       , EditIcon
       ,SupervisorAccountIcon
       ,NotSupervisorAccountIcon
       , XIcon } from '../../../../assets/icons/icons';
import NoRowsDataGridComponent from '../../../../components/NoRowsDataGridComponent/NoRowsDataGridComponent.tsx';
import { DataGridPremium,
	LicenseInfo,
	GridColDef,
	GridRowModel
} from '@mui/x-data-grid-premium';

LicenseInfo.setLicenseKey(
	'a5fb85400407876bcf9c1364b93581a5Tz0xMDEzNzksRT0xNzYyNTM4ODk3MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y',
);

function Utenti(props:any) {
	const navigate = useNavigate();
	const auth = useContext(AuthContext);
	const urlParams = useParams();
	const { setPageName } = useContext(AppAuthGlobalVariables);

	const [ rows, setRows ] = useState<GridRowModel[]>([]);
	const [ loading , setLoading ] = useState(true);

	const ActionHeader:any = () => {
		return <IconButton onClick={()=>navigate('/utenti/new')}><AddIcon addClass='font-16'/></IconButton>
	}

	const ActionCell:any = (params:any) => {
		return <IconButton onClick={()=>{navigate(`/utenti/${params.id}`)}}>
					<EditIcon addClass='font-16'/>
			</IconButton>;
	}

	const ActiveCell:any = (params:any) => {
		if (params.field==='ATTIVO'){
			return params.row.DATA_CANC ? <XIcon addClass='font-16 textRed'/> : <CheckIcon addClass='font-16 textGreen'/>;
		}else{
			return params.value === 'N' ? <XIcon addClass='font-16 textRed'/> : <CheckIcon addClass='font-16 textGreen'/>;
		}
	}

	const AdminCell:any = (params:any) => {
		return params.row.FLAG_ADMIN === 'Y' ? <SupervisorAccountIcon addClass='font-16'/> : <NotSupervisorAccountIcon addClass='font-16'/>;
	}

	const columns:GridColDef[] = [
		{ field: 'COGNOME', headerClassName: 'header-cell cognome', headerName: "Cognome", type: 'text', width: 300, editable: false},
		{ field: 'NOME', headerClassName: 'header-cell nome', headerName: "Nome", type: 'text', width: 300, editable: false},
		{ field: 'EMAIL_ADDRESS', headerClassName: 'header-cell email', headerName: "Email", type: 'text', width: 300, editable: false},
		{ field: 'FLAG_ADMIN', headerClassName: 'header-cell flagAdmin', headerName: "Admin", type: 'text', width: 70, editable: false, align: 'center', headerAlign: 'center',
			renderCell: (params:any) => AdminCell(params)},
		{ field: 'FLAG_AMMINISTRAZIONE', headerClassName: 'header-cell flagAmministrazione', headerName: "Amministrazione", type: 'text', width: 150, editable: false, align: 'center', headerAlign: 'center',
			renderCell: (params:any) => ActiveCell(params)},
		{ field: 'FLAG_TALENT_MANAGER', headerClassName: 'header-cell flagTalentManager', headerName: "Talent Manager", type: 'text', width: 150, editable: false, align: 'center', headerAlign: 'center',
			renderCell: (params:any) => ActiveCell(params)},
		{ field: 'FLAG_REF_COMM', headerClassName: 'header-cell referenteCommerciale', headerName: "Referente Commerciale", type: 'text', width: 150, editable: false, align: 'center', headerAlign: 'center',
			renderCell: (params:any) => ActiveCell(params)},
		{ field: 'ATTIVO', headerClassName: 'header-cell attivo', headerName: "Attivo", type: 'text', width: 70, editable: false, align: 'center', headerAlign: 'center',
			renderCell: (params:any) => ActiveCell(params)},
		{ field: 'ACTION', renderHeader: ()=>ActionHeader(), headerClassName: 'header-cell', headerName: "", type: 'text', width: 60, align: 'center', headerAlign: 'center', editable: false, sortable: false,
			renderCell: (params:any) => ActionCell(params),disableColumnMenu: true}
	];

  	const getUtenti = async () => {
		try {    
			let axiosParams = {
				url		: 'users',
				method	: 'get',
				baseURL	: config.API_URL,
				headers	: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`}
			}
			let response = await axios(axiosParams);

			if (response.status === 200){
				setRows(response.data);
			}
			setLoading(false);
		} catch (e:any) {
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
 	}

	useEffect(() => {
		setPageName?.(urlParams['*']);
			( async () => {
				await getUtenti();
			})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

 	return (
    	<div className='component-container utenti'>
        	{ loading && ( <CircleProgress/>)}
        	<div className='component-card'>
				<DataGridPremium
					className='dataGrid'
					slots={{
						noRowsOverlay: NoRowsDataGridComponent,
					}}
					initialState={{ pinnedColumns: { left: ['ACTION'], right: [] } }}
					rows={rows}
					columns={columns}
					rowHeight={30}
					hideFooter={true}
					isCellEditable={(params) => params.id!==0}
					disableColumnMenu={false}
                    disableColumnReorder={true}
                    disableAggregation={true}
				/>
        	</div>
    	</div>
  	)
}

export default Utenti