import React, { useContext, useEffect, useState } from 'react'
import NoRowsDataGridComponent from '../../../../../components/NoRowsDataGridComponent/NoRowsDataGridComponent.tsx';
import { DataGridPremium,
	LicenseInfo,
	GridColDef,
	GridRowModel,
	GRID_DATE_COL_DEF,
	GridRenderEditCellParams,
	GridColTypeDef,
	GridCellParams,
	GridFilterItem,
	useGridApiContext,
	GridFilterInputValueProps,
	GridEditInputCell
} from '@mui/x-data-grid-premium';
import { isValid } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import locale from 'date-fns/locale/it';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { TextFieldProps } from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { XIcon, AddIcon, CheckBoxEmptyIcon, CheckBoxFilledIcon, EuroIcon, SaveIcon } from '../../../../../assets/icons/icons.tsx';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import config from '../../../../../config.ts';
import { AuthContext } from '../../../../../contexts/authContext.tsx';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { formatNum, formatNumbersIt, getDateFromString, getStringFromDate, getStringMonthYearFromDate } from '../../../../../utils/CommonUtilities.ts';
import TextField from '@mui/material/TextField';

LicenseInfo.setLicenseKey(
	'a5fb85400407876bcf9c1364b93581a5Tz0xMDEzNzksRT0xNzYyNTM4ODk3MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y',
); 

function buildApplyDateFilterFn(
		filterItem: GridFilterItem,
		compareFn: (value1: number, value2: number) => boolean,
		showTime: boolean = false,
  	) {
		if (!filterItem.value) {
		return null;
		}

		// Make a copy of the date to not reset the hours in the original object
		const filterValueCopy = new Date(filterItem.value);
		filterValueCopy.setHours(0, 0, 0, 0);

		const filterValueMs = filterValueCopy.getTime();

		return ({ value }: GridCellParams<any, Date>): boolean => {
			if (!value) {
				return false;
			}

			// Make a copy of the date to not reset the hours in the original object
			const dateCopy = new Date(value);
			dateCopy.setHours(
				showTime ? value.getHours() : 0,
				showTime ? value.getMinutes() : 0,
				0,
				0,
			);
			const cellValueMs = dateCopy.getTime();

			return compareFn(cellValueMs, filterValueMs);
		};
  	}

function getDateFilterOperators(
		showTime: boolean = false,
	): GridColTypeDef['filterOperators'] {
		return [
			{
				value: 'is',
				getApplyFilterFn: (filterItem) => {
				return buildApplyDateFilterFn(
					filterItem,
					(value1, value2) => value1 === value2,
					showTime,
				);
				},
				InputComponent: GridFilterDateInput,
				InputComponentProps: { showTime },
			},
			{
				value: 'not',
				getApplyFilterFn: (filterItem) => {
				return buildApplyDateFilterFn(
					filterItem,
					(value1, value2) => value1 !== value2,
					showTime,
				);
				},
				InputComponent: GridFilterDateInput,
				InputComponentProps: { showTime },
			},
			{
				value: 'after',
				getApplyFilterFn: (filterItem) => {
				return buildApplyDateFilterFn(
					filterItem,
					(value1, value2) => value1 > value2,
					showTime,
				);
				},
				InputComponent: GridFilterDateInput,
				InputComponentProps: { showTime },
			},
			{
				value: 'onOrAfter',
				getApplyFilterFn: (filterItem) => {
				return buildApplyDateFilterFn(
					filterItem,
					(value1, value2) => value1 >= value2,
					showTime,
				);
				},
				InputComponent: GridFilterDateInput,
				InputComponentProps: { showTime },
			},
			{
				value: 'before',
				getApplyFilterFn: (filterItem) => {
				return buildApplyDateFilterFn(
					filterItem,
					(value1, value2) => value1 < value2,
					showTime,
				);
				},
				InputComponent: GridFilterDateInput,
				InputComponentProps: { showTime },
			},
			{
				value: 'onOrBefore',
				getApplyFilterFn: (filterItem) => {
				return buildApplyDateFilterFn(
					filterItem,
					(value1, value2) => value1 <= value2,
					showTime,
				);
				},
				InputComponent: GridFilterDateInput,
				InputComponentProps: { showTime },
			},
			{
				value: 'isEmpty',
				getApplyFilterFn: () => {
				return ({ value }): boolean => {
					return value == null;
				};
				},
				requiresFilterValue: false,
			},
			{
				value: 'isNotEmpty',
				getApplyFilterFn: () => {
				return ({ value }): boolean => {
					return value != null;
				};
				},
				requiresFilterValue: false,
			},
		];
}
  
const dateAdapter = new AdapterDateFns({ locale });

/**
 * `date` column
 */

const dateColumnType: GridColTypeDef<Date, string> = {
	...GRID_DATE_COL_DEF,
	resizable: false,
	renderEditCell: (params) => {
		if(typeof params.value !== 'object'){
			params.value = undefined
		}
	  	return <GridEditDateCell {...params} />;
	},
	filterOperators: getDateFilterOperators(),
	valueFormatter: (params) => {
		if (typeof params.value === 'string') {
			return params.value;
		}
		if (params.value) {
			return dateAdapter.format(params.value, 'keyboardDate');
		}
		return '';
	},
};
  
const GridEditDateInput = styled(InputBase)({
	fontSize: 'inherit',
	padding: '0 9px',
});
  
function WrappedGridEditDateInput(props: TextFieldProps) {
	const { InputProps, ...other } = props;
	return (
		<GridEditDateInput fullWidth {...InputProps} {...(other as InputBaseProps)} />
	);
}
  
function GridEditDateCell({
		id,
		field,
		value,
		colDef,
  	}: GridRenderEditCellParams<any, Date | string | null>) {
		const apiRef = useGridApiContext();
  
		const Component = colDef.type === 'dateTime' ? DateTimePicker : DatePicker;
	
		const handleChange = (newValue: unknown) => {
			apiRef.current.setEditCellValue({ id, field, value: newValue });
		};
	
		return (
			<Component
				value={value}
				autoFocus
				onChange={handleChange}
				slots={{ textField: WrappedGridEditDateInput }}
			/>
		);
}
  
function GridFilterDateInput(
	props: GridFilterInputValueProps & { showTime?: boolean },
) {
	const { item, showTime, applyValue, apiRef } = props;
  
	const Component = showTime ? DateTimePicker : DatePicker;
  
	const handleFilterChange = (newValue: unknown) => {
	  	applyValue({ ...item, value: newValue });
	};

	return (
		<Component
			value={item.value || null}
			autoFocus
			label={apiRef.current.getLocaleText('filterPanelInputLabel')}
			slotProps={{
				textField: {
					variant: 'standard',
				},
				inputAdornment: {
					sx: {
					'& .MuiButtonBase-root': {
						marginRight: -1,
					},
					},
				},
			}}
			onChange={handleFilterChange}
		/>
	);
}
  
function PianoFatturazione(props:any) {
	const auth = useContext(AuthContext);
	const [rows, setRows] = useState<GridRowModel[]>([]);
	const [loadingAction, setLoadingAction] = useState(false);
	const [stanziato, setStanziato] = useState<number | string>(0);
	const [residuo, setResiduo] = useState<number | string>(0);

	const [confermaAttivitaAmount, setConfermaAttivitaAmount] = useState<number | string>(0);
	const [fatturato, setFatturato] = useState<number | string>(0);

	useEffect(() => {
		async function init(){
			//await props.getProposta();
			await loadRighePianoFatt();
		}
		init();
	},[])

	const ActionHeader:any = (params:any, table:number) => {
		return (loadingAction ? 
					<CircularProgress className='font-16 table-circular-progress' /> 
					:
					<IconButton onClick={()=>addNewRow(table)}>
						<AddIcon addClass='add-new-row'/>
					</IconButton>
				)
	}

	const ActionCell:any = (params:any) => {
		return (
			<div className='flexed-center full-width'>
				{loadingAction ? 
					<CircularProgress className='font-16 table-circular-progress' /> 
					:
					params.row.FLAG_FATTURATO !== 'Y' 
						?
						<IconButton onClick={()=>delPianoFattRow(params)}><XIcon addClass='font-16'/></IconButton>
						:
						null
				}
			</div>
		)
	}

	const ActiveCell:any = ( params:any ) => {
        return ( !params.value || params.value === 'N'
            ? <div className='flexed cursor-pointer' onClick={!(params.row.DATA_FATTURAZIONE instanceof Date && !isNaN(params.row.DATA_FATTURAZIONE)) ? ()=>{} : (clickEvent) => {modRowFlag('Y', params)}}><CheckBoxEmptyIcon addClass='font-22'/></div>
            : <div className='flexed cursor-pointer' onClick={!(params.row.DATA_FATTURAZIONE instanceof Date && !isNaN(params.row.DATA_FATTURAZIONE)) ? ()=>{} : (clickEvent) => {modRowFlag('N', params)}}><CheckBoxFilledIcon addClass='font-22'/></div>
        )
    }

	const ImportoFattCell:any = (params:any) => {
		params.value = !params.value ? '' : (params.value+'').replaceAll('.', ',');
		return <GridEditInputCell {...params}></GridEditInputCell>
	}

	const columns: GridColDef[] = [
		{ field: 'ACTION', renderHeader: (params)=>ActionHeader(params, 1), headerClassName: 'header-cell action', headerName: "", width: 50, editable: false, align: 'center', headerAlign: 'center', sortable: false, renderCell: (params) => ActionCell(params)},
		{ field: 'DATA_FATTURAZIONE', headerClassName: 'header-cell annoMese', headerName: "Data Fatturazione", ...dateColumnType, width: 200, editable: true/*,  valueFormatter: (params) => {return formatDateForCell(params.value)} */},
		{ field: 'IMPORTO', headerClassName: 'header-cell importo', headerName: "Importo", type: 'text', width: 200, editable: true, align: 'right', headerAlign: 'right', renderEditCell: (params:any) => ImportoFattCell(params) , valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : ''}},
		{ field: 'FLAG_CONFERMA_ATT', headerClassName: 'header-cell confermaAttivitaTalent', headerName: "Conferma Attività Talent", type: 'text', width: 200, align: 'center', headerAlign: 'center',editable: false, renderCell: (params:any) => ActiveCell(params)},
		{ field: 'FLAG_FATTURATO', headerClassName: 'header-cell fatturato', headerName: "Fatturato", type: 'text', width: 150, editable: false, align: 'center', headerAlign: 'center', renderCell: (params:any) => ActiveCell(params)},
		{ field: 'NOTE', headerClassName: 'header-cell note', headerName: "Note", type: 'text', flex: 1, editable: true},
	];

	const hideColumns = {
		ACTION: props.proposalTotalDisabled ? false : true,
		FLAG_CONFERMA_ATT: (props.kPropStatus === '04' || props.kPropStatus === '05' || props.kPropStatus === '06') ? true : false,
		FLAG_FATTURATO: (props.kPropStatus === '04' || props.kPropStatus === '05' || props.kPropStatus === '06') ? true : false,
	}

	const loadRighePianoFatt = async() => {
		setLoadingAction(true);
		try{
			const paramsRigheDettaglio:any = {
                kProposta: props.kProposta,
            }

            let axiosParams = {
                url: '',
                method: 'get',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                params: {
                  ...paramsRigheDettaglio
                }
            }

			axiosParams.url = 'piani-fatturazione';
			let response = await axios(axiosParams);

			if(response.status === 200){
				const data = response.data;
				let ammontareFatturabile:number = 0;
				let ammontareCorrente:number = props.fatturazioneDirettaTalent 
												? 
												((typeof props.margineDoom !== 'number' ? Number(props.margineDoom.replaceAll(',', '.')) : props.margineDoom) || 0) 
												: 
												props.bBudget 
													? 
													((typeof props.budget !== 'number' ? Number(props.budget.replaceAll(',', '.')) : props.budget) || 0) 
													: 
													(props.totaleRicavi || 0);
				let confermatoAttivitaTalent:number = 0;
				let fatturato:number = 0;
				for (let index = 0; index < data.length; index++) {
					const element = data[index];
					element.DATA_FATTURAZIONE = getDateFromString(element.DATA_FATTURAZIONE);
					ammontareFatturabile = element.IMPORTO + ammontareFatturabile;

					if(element.FLAG_CONFERMA_ATT==="Y")confermatoAttivitaTalent = element.IMPORTO + confermatoAttivitaTalent;
					if(element.FLAG_FATTURATO==="Y")fatturato = element.IMPORTO + fatturato;
				}

				setStanziato(formatNumbersIt(formatNum(ammontareFatturabile)));
				setResiduo(formatNumbersIt(formatNum(ammontareCorrente) - formatNum(ammontareFatturabile)));

				setConfermaAttivitaAmount(formatNumbersIt(formatNum(confermatoAttivitaTalent)));
				setFatturato(formatNumbersIt(formatNum(fatturato)));

				setRows(data);
				setLoadingAction(false);
			}	
		} catch (e:any) {
			setLoadingAction(false);
			if(e.response && e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response && e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
	}

	const addNewRow = async(table:number) => {
		setLoadingAction(true);
		try{
			if(props.kPropStatus === '04'){
				props.alterStatusProposta('06');
			}

			const pianoFattParams:any = {
                kProposta: props.kProposta,
				impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : ''
            }

            let axiosParams = {
                url: 'piano-fatturazione-ins',
                method: 'post',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data: {
                  ...pianoFattParams
                }
            }

			let response = await axios(axiosParams);
			if(response.status === 200){
				setRows([...rows, {
					id:response.data.nNumeroPianoFatt
				}]);
			}
			setLoadingAction(false);
		}catch(e:any){
			setLoadingAction(false);
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
  	}

	const modRow = async(params:any, oldRow:any) => {
		try{
			if(props.proposalTotalDisabled){
				return oldRow;
			}else if(oldRow.FLAG_FATTURATO === 'Y' && (oldRow.NOTE === params.NOTE)){
				//Se la riga è fatturata e la proposta è contrattualizzata in modifica, non può essere toccata, tranne le note
				return oldRow;
			}

			setLoadingAction(true);
			//bisogna che la data di fatturazione sia valida prima di interagire con gli altri campi
			//e che la data inputtata prima sia esistente
			if(!isValid(params.DATA_FATTURAZIONE)){
				if(!oldRow.DATA_FATTURAZIONE){
					NotificationManager.warning('Inserire una data di fatturazione', 'Attenzione', 5000);
				}
				setLoadingAction(false);
				return oldRow;
			}
			
			let dateFromPicker = isValid(params.DATA_FATTURAZIONE) ? new Date(params.DATA_FATTURAZIONE) : '19750101';
			let processedDateMonth = typeof dateFromPicker === 'string' ? dateFromPicker : getStringFromDate(dateFromPicker);
			let pianoFattParams:any = {
				kProposta: props.kProposta,
                kPianoFatt: params.id,
				dataFatturazione: processedDateMonth,
				importo: typeof params.IMPORTO !== 'number' ? ((params.IMPORTO === null) || (params.IMPORTO === undefined)) ? '' : Number(params.IMPORTO.replaceAll(',', '.')) : params.IMPORTO,
				flagConfermaAttivitaTalent: params.FLAG_CONFERMA_ATT,
				flagFatturato: params.FLAG_FATTURATO,
				note: params.NOTE,
				impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : ''
            }

			let prevDateFromPicker = new Date(oldRow.DATA_FATTURAZIONE);
			let prevProcessedDateMonth = getStringFromDate(prevDateFromPicker);
			if((processedDateMonth !== prevProcessedDateMonth) && props.kPropStatus === '04'){
				props.alterStatusProposta('06');
			}else if((params.IMPORTO !== oldRow.IMPORTO) && props.kPropStatus === '04'){
				props.alterStatusProposta('06');
			}

            let axiosParams = {
                url: '',
                method: 'post',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data: {
                  ...pianoFattParams
                }
            }

			axiosParams.url = 'piano-fatturazione-mod';
			let response = await axios(axiosParams);

			if(response.status === 200){
				setLoadingAction(false);
				await loadRighePianoFatt();
				return params;
			}
		}catch(e:any){
			setLoadingAction(false);
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
			return oldRow;
		}
	}

	const modRowFlag = async(value:any, params:any) => {
		if(props.proposalTotalDisabled){
			return;
		}
		try{
			setLoadingAction(true);
			let dateFromPicker = new Date(params.row.DATA_FATTURAZIONE);
			let processedDateMonth = getStringFromDate(dateFromPicker);
			let pianoFattParams:any = {
				kProposta: props.kProposta,
                kPianoFatt: params.id,
				dataFatturazione: processedDateMonth,
				importo: params.row.IMPORTO,
				flagConfermaAttivitaTalent: params.field === 'FLAG_CONFERMA_ATT' ? value : params.row.FLAG_CONFERMA_ATT,
				flagFatturato: params.field === 'FLAG_FATTURATO' ? value : params.row.FLAG_FATTURATO,
				note: params.row.NOTE,
				impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : ''
            }

            let axiosParams = {
                url: '',
                method: 'post',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data: {
                  ...pianoFattParams
                }
            }

			axiosParams.url = 'piano-fatturazione-mod';
			let response = await axios(axiosParams);

			if(response.status === 200){
				await loadRighePianoFatt();
				setLoadingAction(false);
			}
		}catch(e:any){
			setLoadingAction(false);
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
			return;
		}
	}

	const delPianoFattRow = async(params:any) => {
		setLoadingAction(true)
		try{
			if(props.kPropStatus === '04'){
				props.alterStatusProposta('06');
			}

			let dettaglioRigaParams:any = {
                kPianoFatt: params.id,
				impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : ''
            }

            let axiosParams = {
                url: 'piano-fatturazione-del',
                method: 'post',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data: {
                  ...dettaglioRigaParams
                }
            }

			await axios(axiosParams);
			await loadRighePianoFatt()
			setLoadingAction(false);
		}catch(e:any){
			setLoadingAction(false);
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
	}

	return (
		<div className='pianoFatturazione-container'>
			<div className='row-residuo'>
				<div className='box-residuo'>
					<TextField
						id="standard-basic"
						variant="outlined"
						label="Totale Da Fatturare"
						value={
							props.fatturazioneDirettaTalent
							?
								formatNumbersIt((typeof props.margineDoom !== 'number' ? Number(props.margineDoom.replaceAll(',', '.')) : props.margineDoom))
							:
							props.bBudget 
							?
								formatNumbersIt((typeof props.budget !== 'number' ? Number(props.budget.replaceAll(',', '.')) : props.budget))
							:
								formatNumbersIt(props.totaleRicavi)
						}
						inputProps={{readOnly: true,sx:{textAlign:'right'}}}
						InputProps= {{ endAdornment: <EuroIcon addClass='pad-left'/> }}
					/>
					<TextField
						id="standard-basic" 
						variant="outlined" 
						label="In Fatturazione" 
						error={stanziato !== (props.fatturazioneDirettaTalent ? formatNumbersIt((typeof props.margineDoom !== 'number' ? Number(props.margineDoom.replaceAll(',', '.')) : props.margineDoom)) : props.bBudget ? formatNumbersIt(props.budget) : formatNumbersIt(props.totaleRicavi))} 
						value={stanziato} 
						inputProps={{readOnly: true,sx:{textAlign:'right'}}} 
						InputProps= {{ endAdornment: <EuroIcon addClass='pad-left'/> }}
					/>
					<TextField 
						id="standard-basic" 
						variant="outlined" 
						label="Residuo" 
						error={residuo !== '0'} 
						value={residuo} 
						inputProps={{readOnly: true,sx:{textAlign:'right'}}} 
						InputProps= {{ endAdornment: <EuroIcon addClass='pad-left'/> }}
					/>
				</div>
				{(props.kPropStatus === '04' || props.kPropStatus === '05')&&
					(<div className='box-conferma'>
						<TextField id="standard-basic" variant="outlined" label="Conferma Attività Talent" value={confermaAttivitaAmount} inputProps={{readOnly: true,sx:{textAlign:'right'}}} InputProps= {{ endAdornment: <EuroIcon addClass='pad-left'/> }}/>
						<TextField id="standard-basic" variant="outlined" label="Fatturato" value={fatturato} inputProps={{readOnly: true,sx:{textAlign:'right'}}} InputProps= {{ endAdornment: <EuroIcon addClass='pad-left'/> }}/>
					</div>)
				}
			</div>
			<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
				<DataGridPremium
					className='dataGrid'
					slots={{
						noRowsOverlay: NoRowsDataGridComponent,
					}}
					initialState={{ pinnedColumns: { left: ['ACTION'], right: [''] } }}
					rows={rows}
					columns={columns}
					columnVisibilityModel={hideColumns}
					rowHeight={30}
					hideFooter={true}
					disableColumnMenu={true}
					disableColumnReorder={true}
					processRowUpdate={(params:any, oldRow:any)=>modRow(params, oldRow)}
					onProcessRowUpdateError={(error:any) => {console.log(error)}}
					//isCellEditable={(params) => params.row.FLAG_FATTURATO !== 'Y' ? true : false}
				/>
			</LocalizationProvider>
		</div>
	)
}

export default PianoFatturazione