import React, { createContext, useState } from 'react'

export interface Tools {
  pageName?: string
  userEmail?: string
  setPageName?: Function
  setUserEmail?: Function
  changePageName?: Function
  changeListaPropostaPageName?: Function
}

const defaultTools: Tools = {
  pageName: 'Dashboard',
}

export const AppAuthGlobalVariables = createContext(defaultTools)

const AppAuthContext = ({children}: any) => {
  const regex = /[-/]/g;
  const statusMapping:{ [key: string]: string; } = {
    '01': 'Lead',
    '02': 'Confermate',
    '03': 'Rifiutate da Cliente/Agenzia',
    '04': 'Contrattualizzate',
    '05': 'Chiuse',
    '06': 'Contrattualizzate in modifica',
    '07': 'Cestino',
    '08': 'Rifiutate da Talent'
  }

  const [pageName, setPageName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  
  const changePageName = (params:string) => {
    let processedPage = params.replaceAll(regex, ' ');
    setPageName(processedPage);
  }

  const changeListaPropostaPageName = (params:string) => {
    let processedPage = 'Proposte' + (params ? ' - ' + statusMapping[params] : '');
    setPageName(processedPage);
  }

  const availableTools: Tools = {
    pageName,
    userEmail,
    setPageName,
    setUserEmail,
    changePageName,
    changeListaPropostaPageName
  }

  return (<AppAuthGlobalVariables.Provider value={availableTools}>{children}</AppAuthGlobalVariables.Provider>)
}

export default AppAuthContext;