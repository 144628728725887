import React, {useState, useEffect, useContext, SyntheticEvent} from 'react';
import './Localita.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { AppAuthGlobalVariables } from '../../../../contexts/AppAuthContext';
import { NotificationManager } from 'react-notifications';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import config from '../../../../config';
import CircleProgress from '../../../../components/CircleProgress/CircleProgress';
import { AutocompleteLocationObject }       from '../../../../utils/ConfigInterfaces';
import { AuthContext }         from '../../../../contexts/authContext';
import NoRowsDataGridComponent from '../../../../components/NoRowsDataGridComponent/NoRowsDataGridComponent.tsx';
import { DataGridPremium,
    LicenseInfo,
    GridColDef,
    GridRowModel
} from '@mui/x-data-grid-premium';

LicenseInfo.setLicenseKey(
'a5fb85400407876bcf9c1364b93581a5Tz0xMDEzNzksRT0xNzYyNTM4ODk3MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y',
);

function Localita(props:any) {

	const { setPageName } = useContext(AppAuthGlobalVariables);
	const urlParams = useParams();
	const auth = useContext(AuthContext);
	const [ depth            ,setDepth            ] = useState<number | null>(null);
	const [ nationRows       ,setNationRows       ] = useState<GridRowModel[]>([]);
	const [ regionRows       ,setRegionRows       ] = useState<GridRowModel[]>([]);
	const [ provinceRows     ,setProvinceRows     ] = useState<GridRowModel[]>([]);
	const [ municipalityRows ,setMunicipalityRows ] = useState<GridRowModel[]>([]);
	const [ loading          ,setLoading          ] = useState<boolean>(true);

	const [ nationAutocompleteVal   ,setNationAutocompleteVal   ] = useState<any>({ id: null, label: '' });
	const [ regionAutocompleteVal   ,setRegionAutocompleteVal   ] = useState<any>({ id: null, label: '' });
	const [ provinceAutocompleteVal ,setProvinceAutocompleteVal ] = useState<any>({ id: null, label: '' });

	useEffect(() => {
		setPageName?.(urlParams['*'])
		setDepth(1);
		//   getLocations(true, 1); // !!! Quando sarà possibile modificare la Nazione, Non utilizzare qui il setDepth(1) perchè non potrei passare il firstLoad a true
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	const columns:GridColDef[] = [
		{ field: 'label', headerClassName: 'header-cell descrizioneArea', headerName: "Nome", type: 'text', flex: 1, editable: false },
	]  

	const getLocations = async( /*firstLoad:boolean = false,*/ forceDepth:number | null= depth, regione:{ id:number, label:string } = regionAutocompleteVal, provincia:{id:number, label:string} = provinceAutocompleteVal) => {
		setLoading(true);
		try{
		let axiosParams = {
			url: 'locations',
			method: 'get',
			baseURL: config.API_URL,
			headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
			params: {
			depth: forceDepth,
			regione: regione?.id,
			provincia: provincia?.id
			}
		}

		let response = await axios(axiosParams);

		if ( response.status === 200 ) {
			if ( depth === 1 || forceDepth === 1 ) {
				setNationRows( response.data );
				let itaObj = response.data.find((nazione:{id: string, label: string}) => nazione.label === 'ITALIA')
				setNationAutocompleteVal(itaObj)
			} else if ( depth === 2 ) {
				setRegionRows( response.data );
			} else if ( depth === 3 ) {
				setProvinceRows( response.data );
			} else if ( depth === 4 ) {
				setMunicipalityRows( response.data );
			}
		}

		//   if ( firstLoad ) {
		//     let itaObj = response.data.find((nazione:{id: string, label: string}) => nazione.label === 'ITALIA')
		//     setNationAutocompleteVal(itaObj)
		//   }

		} catch( e:any ) {
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		} finally {
			setLoading(false);
		}
	}

	const deeperSelection = (params:any) => {
		if( depth === 1 ){
			setNationAutocompleteVal( params.row );
		}else if( depth === 2 ){
			setRegionAutocompleteVal( params.row );
		}else if( depth === 3 ){
			setProvinceAutocompleteVal( params.row );
		}
	}

	const handleChange = (newValue:any, newDepth:number) => {
		if ( !newValue ) {
			setDepth(newDepth);
		}
		newDepth === 1 
			? setNationAutocompleteVal({ ...newValue  || { id: null, label: '' }})
			: newDepth === 2
				? setRegionAutocompleteVal({ ...newValue  || { id: null, label: '' }})
				: setProvinceAutocompleteVal({ ...newValue || { id: null, label: '' }});
	}

	useEffect(() => {
		// svuota i campi sotto
		setRegionAutocompleteVal({ id: null, label: '' });
		setProvinceAutocompleteVal({ id: null, label: '' });
		setRegionRows([]);
		setProvinceRows([]);
		setMunicipalityRows([]);
		if (( nationAutocompleteVal.id !== null ) && ( nationAutocompleteVal.label === 'ITALIA' )) {
			setDepth(2);
			// getLocations(false, (depth /* + 1 */));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ nationAutocompleteVal ]);

	useEffect(() => {
		// svuota i campi sotto 
		setProvinceAutocompleteVal({ id: null, label: '' });
		setProvinceRows([]);
		setMunicipalityRows([]);
		if (( nationAutocompleteVal.id !== null ) && ( regionAutocompleteVal.id !== null ) && ( Object.keys(regionAutocompleteVal).length !== 0 )) {
			setDepth(3);
			// getLocations(false, (depth /* + 1 */), regionAutocompleteVal);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ regionAutocompleteVal ]);

	useEffect(() => {
		setMunicipalityRows([]);
		if (( nationAutocompleteVal.id !== null ) && ( regionAutocompleteVal.id !== null ) && ( provinceAutocompleteVal.id !== null ) && ( Object.keys(provinceAutocompleteVal).length !== 0 )) {
			setDepth(4);
			// getLocations(false, (depth /* + 1 */), regionAutocompleteVal, provinceAutocompleteVal);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ provinceAutocompleteVal ]);

	useEffect(() => {
		if ( depth !== null ) {
			getLocations();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ depth ]);

  	return (
    	<div className='component-container localita'>
			{ loading && <CircleProgress/> }
			<div className='component-card'>
          		<div className='depths-container'>
					<Autocomplete
						disablePortal
						id       = "nations-filter"
						value    = { nationAutocompleteVal }
						options  = { nationRows }
						multiple = { false }
						disabled = { true }
						onChange = {( event:SyntheticEvent, newValue: AutocompleteLocationObject[] ) => handleChange( newValue, 1 ) }
						sx       = {{ width: 300 }}
						renderInput = {( params ) =>
							<TextField 
							{ ...params } 
							variant = "standard" 
							label   = "Nazione" 
							/>
						} 
					/>
					<Autocomplete
						disablePortal
						id       = "regions-filter"
						value    = { regionAutocompleteVal || '' }
						options  = { regionRows }
						multiple = { false }
						onChange = {( event:SyntheticEvent, newValue: AutocompleteLocationObject[] ) => handleChange( newValue, 2 ) }
						sx       = {{ width: 300 }}
						renderInput = {( params ) =>
							<TextField 
							{ ...params  } 
							variant = "standard" 
							label   = "Regione" 
							/>
						}
					/>
					<Autocomplete
						disablePortal
						id       = "provinces-filter"
						value    = { provinceAutocompleteVal }
						options  = { provinceRows }
						multiple = { false }
						onChange = {( event:SyntheticEvent, newValue: AutocompleteLocationObject[] ) => handleChange( newValue, 3 ) }
						sx       = {{ width: 300 }}
						renderInput = {( params ) =>
							<TextField 
							{ ...params } 
							variant = "standard" 
							label   = "Provincia" 
							/>
						}
					/>
				</div>
          		<DataGridPremium
					className  = 'dataGrid'
					slots = {{
						noRowsOverlay: NoRowsDataGridComponent,
					}}
					initialState = {{ pinnedColumns: { left: [], right: [] } }}
					rows = { depth === 1 
							? nationRows
							: depth === 2
							? regionRows
							: depth === 3
								? provinceRows
								: depth === 4
								? municipalityRows
								: [{ id: 0, label: '' }]
					}
					columns              = { columns }
					rowHeight            = { 30 }
					hideFooter           = { true }
					isCellEditable       = {( params ) => params.id!==0 }
					disableColumnMenu    = { true }
					disableColumnReorder = { true }
					onRowDoubleClick     = {( params ) => deeperSelection( params )}
          		/>
    		</div>
  		</div>
  	)
}

export default Localita;