import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react'
import TextField from '@mui/material/TextField';
import { IconButton } from '@mui/material';
import { AddIcon
	   , EuroIcon, NoteEdit, NoteNuovo, PercentageIcon, SaveIcon, ThreeVerticalDot, 
	   XIcon} from '../../../../../assets/icons/icons';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { NotificationManager } from 'react-notifications';
import config from '../../../../../config.ts';
import { AuthContext } from '../../../../../contexts/authContext.tsx';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGridPremium,
	LicenseInfo,
	GridColDef,
	GridRowModel,
	GridEditInputCell,
	GridRowParams,
	GridCellParams,
	GridRow,
	GridCellModes,
	GridCellModesModel,
	} from '@mui/x-data-grid-premium';

import { formatNumbersIt, testNumberInputPercentagesTwoDecimals } from '../../../../../utils/CommonUtilities.ts';

LicenseInfo.setLicenseKey(
	'a5fb85400407876bcf9c1364b93581a5Tz0xMDEzNzksRT0xNzYyNTM4ODk3MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y',
);

const Proposta = forwardRef((props:any, ref:any) => {
	useImperativeHandle(ref, () => ({
		loadRigheDettaglio
	}));

	const auth = useContext(AuthContext);

	const [firstRows, setFirstRows] = useState<GridRowModel[]>([]);
	const [secondRows, setSecondRows] = useState<GridRowModel[]>([]);
	const [thirdRows, setThirdRows] = useState<GridRowModel[]>([]);

	const [isFirstBoxToRender, setIsFirstBoxToRender] = useState<any>(true);
	const [isSecondBoxToRender, setIsSecondBoxToRender] = useState<any>(false);
	const [isThirdBoxToRender, setIsThirdBoxToRender] = useState<any>(false);

	const [textareaCharactersLeft, setTextareaCharactersLeft] = useState(0);
	const [currentNoteValue, setCurrentNoteValue] = useState('');

	const [talentList, setTalentList] = useState<any>([]);
	const [talentInterniList, setTalentInternitList] = useState<any>([]);
	const [talentEsterniList, setTalentEsterniList] = useState<any>([]);
	const [categorieCostiList, setCategorieCostiList] = useState<any>([]);

	const [latestSelectedRow, setLatestSelectedRow] = useState<any>({id: '', type: 0});

	const [dnValue, setDnValue] = useState<number | string>('');
	const [margineDoom, setMargineDoom] = useState<number | string>('');
	const [incaricoAgenzia, setIncaricoAgenzia] = useState<number | string>('');
	const [cuscinetto, setCuscinetto] = useState<number | string>(''); 
	const [totaleCosti, setTotaleCosti] = useState<number | string>('');
	const [totaleRicaviSenzaDn, setTotaleRicaviSenzaDn] = useState<number | string>('');
	const [totaleRicavi, setTotaleRicavi] = useState<number | string>('');
	const [doomMargineDnPerc, setDoomMargineDnPerc] = useState<number | string>('');
	const [doomMargineFatturatoPerc, setDoomMargineFatturatoPerc] = useState<number | string>('');

	const [mediaPonderata, setMediaPonderata] = useState<number | string>('');

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	//const [propostaParams, setPropostaParams] = useState<any>({});

	const [loadingAction, setLoadingAction] = useState(false);

	const openMenu = Boolean(anchorEl);

	useEffect(() => {
		async function init() {
			setLoadingAction(true);
			await Promise.all([
				getTalent(),
				getCategorieCosti()
			]).then(values => {
				loadRigheDettaglio();
			}).catch((e:any) => {
				if(e.response.status===500){
                	NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
				}else if(e.response.status===401){
					NotificationManager.error('Non Autorizzato', 'Errore', 10000);
					props.logOut();
				}else{
					NotificationManager.error('Errore di Rete', 'Errore', 10000);
				}
			})
		}
		init();
	},[])

	useEffect(() => {
		if(latestSelectedRow.type !== 0){
			setCurrentNoteValue(latestSelectedRow.note)
		}
	},[latestSelectedRow])
	
	const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const loadRigheDettaglio = async() => {
		setLoadingAction(true);
		try{
			const paramsRigheDettaglio:any = {
                kProposta: props.kProposta,
            }

            let axiosParams = {
                url: '',
                method: 'get',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                params: {
                  ...paramsRigheDettaglio
                }
            }

			axiosParams.url = 'proposta-dettagli';
			let response = await axios(axiosParams);

			if(response.status === 200){

				const data = response.data;
				const primaRiga = data[0] || {};
				//dati per calcoli
				setDnValue(formatNumbersIt(primaRiga.DN) || '');
				setMargineDoom(formatNumbersIt(primaRiga.DOOM_MARG) || '');
				//setIncaricoAgenzia(calculatedFields);
				setCuscinetto(formatNumbersIt(primaRiga.COSTI_EXTRA) || '');
				setTotaleCosti(formatNumbersIt(primaRiga.TOT_COSTI) || '');
				setTotaleRicaviSenzaDn(formatNumbersIt(primaRiga.TOT_RICAVI_DN) || '');
				setTotaleRicavi(formatNumbersIt(primaRiga.TOT_RICAVI) || '');
				setDoomMargineDnPerc(formatNumbersIt(primaRiga.DOOM_MARG_DN_PERC) || '');
				setDoomMargineFatturatoPerc(formatNumbersIt(primaRiga.DOOM_MARG_FATT_PERC) || '');	

				setMediaPonderata(formatNumbersIt(primaRiga.MEDIA_POND_FEE_PERC) || '');

				props.setMargineDoomPerc((formatNumbersIt(primaRiga.DOOM_PERC) === '' ? props.margineDoomPerc : formatNumbersIt(primaRiga.DOOM_PERC)) || '');
				props.setTotaleRicavi((primaRiga.TOT_RICAVI) || '');

				let firstTableRows = data.filter((el:any) => el.TIPO_DETTAGLIO === 1);
				let secondTableRows = data.filter((el:any) => el.TIPO_DETTAGLIO === 2);
				let thirdTableRows = data.filter((el:any) => el.TIPO_DETTAGLIO === 3);

				//se sono presenti righe di tipo 2 e 3 allora mostro le tabelle.
				if(secondTableRows.length > 0)setIsSecondBoxToRender(true);
				if(thirdTableRows.length > 0)setIsThirdBoxToRender(true);

				setFirstRows([...firstTableRows]);
				setSecondRows([...secondTableRows]);
				setThirdRows([...thirdTableRows]);

				setLoadingAction(false);
			}
		} catch (e:any) {
			setLoadingAction(false);
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
	}

	const addNewRow = async(table:number) => {
		setLoadingAction(true);
		try{
			if(props.kPropStatus === '04'){
				await props.alterStatusProposta('06');
			}

			const dettaglioRigaParams:any = {
                kProposta: props.kProposta,
                tipoDettaglio: table,
				impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : ''
            }

            let axiosParams = {
                url: '',
                method: 'post',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data: {
                  ...dettaglioRigaParams
                }
            }

			axiosParams.url = 'proposta-dettaglio-ins';
			let response = await axios(axiosParams);

			if(response.status === 200){
				loadRigheDettaglio();
				setLoadingAction(false);
			}	
		}catch(e:any){
			setLoadingAction(false);
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
  	}

	const getTalent = async() => {
		try {    
			let axiosParams = {
				url		: 'talents',
				method	: 'get',
				baseURL	: config.API_URL,
				headers	: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
			}
			let response = await axios(axiosParams);

			if (response.status === 200){
				setTalentList(response.data);
				let talentEsterni = response.data.filter((talent:any) => talent.FLAG_ESTERNO==='Y').sort((a:any, b:any) => {
					const nameA = a.COGNOME.toUpperCase(); 
					const nameB = b.COGNOME.toUpperCase(); 
					if (nameA < nameB) {
					  return -1;
					}
					if (nameA > nameB) {
					  return 1;
					}
					return 0;
				})

				let talentInterni = response.data.filter((talent:any) => talent.FLAG_ESTERNO==='N').sort((a:any, b:any) => {
					const nameA = a.COGNOME.toUpperCase(); 
					const nameB = b.COGNOME.toUpperCase(); 
					if (nameA < nameB) {
						return -1;
					}
					if (nameA > nameB) {
						return 1;
					}
					return 0;
				})

				setTalentEsterniList(talentEsterni);
				setTalentInternitList(talentInterni)
			}
		} catch (e:any) {
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
	}

	const getCategorieCosti = async () => {
        try {
            let axiosParams = {
                url		: 'categorie-costi',
                method	: 'get',
                baseURL	: config.API_URL,
                headers	: { Authorization: `Bearer ${auth.sessionInfo?.accessToken}` },
            }
            let response = await axios(axiosParams);

            if (response.status === 200){
                setCategorieCostiList(response.data);
            }
        } catch (e:any) {
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        }
    }

	const ActionHeader:any = (params:any, table:number) => {
		return (loadingAction ? 
					<CircularProgress className='font-16 table-circular-progress' /> 
					:
					<IconButton onClick={()=>addNewRow(table)}>
						<AddIcon addClass='add-new-row'/>
					</IconButton>)
	}

	const RenderNoteCell = (params:any) => {
		return <IconButton onClick={()=>setCurrentNoteValue(params.row.NOTE)} title={params.row.NOTE}>{!params.row.NOTE ? <NoteEdit addClass='font-16'/> : <NoteNuovo addClass='font-16 yellow-icon'/>}</IconButton>
	}

	const ActionCell:any = (params:any) => {
		return (
			<div className='flexed-center full-width'>
				{loadingAction ? 
					<CircularProgress className='font-16 table-circular-progress' /> 
					:
					<IconButton onClick={(event:any)=>delPropostaDettaglio(params, event)}><XIcon addClass='font-16'/></IconButton>
				}
			</div>
		)
	}

	const FeeCell:any = (params:any) => {
		params.value = params.value === null ? '' : (params.value+'').replaceAll('.', ',');
		return <GridEditInputCell {...params} disabled={!params.row.KTALENT}></GridEditInputCell>
	}

	const CostCell:any = (params:any) => {
		//format in ita della cifra in editing
		//check sul null necessario nel caso in cui sia null il params.value perché stampa null in cella
		params.value = params.value === null ? '' : (params.value+'').replaceAll('.', ',');
		return <GridEditInputCell {...params} disabled={!params.row.KTALENT}></GridEditInputCell>
	}

	const CostCategorieCell:any = (params:any) => {
		params.value = params.value === null ? '' : (params.value+'').replaceAll('.', ',');
		return <GridEditInputCell {...params} disabled={!params.row.KCATEGORIA_COSTI}></GridEditInputCell>
	}

	/* const sortAndFilterTalentByNomeArte = (talentEsterno:string) => {
		let processedArray = talentList.filter((talent:any) => talent.FLAG_ESTERNO===talentEsterno).sort((a:any, b:any) => {
			const nameA = a.NOME_ARTE.toUpperCase(); 
			const nameB = b.NOME_ARTE.toUpperCase(); 
			if (nameA < nameB) {
			  return -1;
			}
			if (nameA > nameB) {
			  return 1;
			}
			return 0;
		  })
		return processedArray;
	} */

	const firstColumns: GridColDef[] = [
		{ field: 'ACTION', renderHeader: (params)=>ActionHeader(params, 1), headerClassName: 'header-cell actionCol', headerName: "", type: 'text', width: 50, editable: false, sortable: false, headerAlign:'center', renderCell: (params) => ActionCell(params)},
		{ field: 'KTALENT', headerClassName: 'header-cell nomeArte', headerName: "Talent", type: 'singleSelect', width: 200, editable: (loadingAction ? false : true), /* valueOptions: sortAndFilterTalentByNomeArte('N'), */valueOptions: talentInterniList, valueFormatter: (params) => {const option = talentList.find((tl:any) => tl.id ===params.value); return option?.label}},
		{ field: 'DATTIVITA', headerClassName: 'header-cell attivita', headerName: "Attività", type: 'text', flex: 1, editable: (loadingAction ? false : true)},
		{ field: 'FEE_PERC', headerClassName: 'header-cell feeDoom', headerName: "Fee Doom", type: 'text', width: 100, align: 'right', headerAlign: 'right', editable: (loadingAction ? false : true), renderEditCell: (params:any) => FeeCell(params), valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' %' : ''}},
		{ field: 'COSTO_TOTALE_FEE_INC', headerClassName: 'header-cell descrizioneArea', headerName: "Budget Fee Incl.", type: 'text', width: 150, align: 'right', headerAlign: 'right', editable: (loadingAction ? false : true), renderEditCell: (params:any) => CostCell(params), valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : ''}},
		{ field: 'COSTO_TOTALE', headerClassName: 'header-cell descrizioneArea', headerName: "Fee Talent", type: 'text', width: 150, align: 'right', headerAlign: 'right', editable: false, valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : ''}},
		{ field: 'NOTE', headerClassName: 'header-cell note', headerName: "Note", type: 'text', width: 55, align:'center', headerAlign:'center', editable: false, sortable: false, renderCell: (params) => RenderNoteCell(params)},
  	];

	const secondColumns: GridColDef[] = [
		{ field: 'ACTION', renderHeader: (params)=>ActionHeader(params, 2), headerClassName: 'header-cell actionCol', headerName: "", type: 'text', width: 50, editable: false, sortable: false, headerAlign:'center', renderCell: (params) => ActionCell(params)},
		{ field: 'KTALENT', headerClassName: 'header-cell nomeArte', headerName: "Talent", type: 'singleSelect', width: 200, editable: (loadingAction ? false : true), /* valueOptions: sortAndFilterTalentByNomeArte('Y'), */valueOptions: talentEsterniList, valueFormatter: (params) => {return talentList.find((tl:any) => tl.id ===params.value)?.label}},
		{ field: 'DATTIVITA', headerClassName: 'header-cell attivita', headerName: "Attività", type: 'text', flex: 1, editable: (loadingAction ? false : true)},
		{ field: 'COSTO_TOTALE', headerClassName: 'header-cell descrizioneArea', headerName: "Costo Totale", type: 'text', width: 150, align: 'right', headerAlign: 'right', editable: (loadingAction ? false : true), renderEditCell: (params:any) => CostCell(params), valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : ''}},
		{ field: 'NOTE', headerClassName: 'header-cell note', headerName: "Note", type: 'text', width: 55, align:'center', headerAlign:'center', editable: false, sortable: false, renderCell: (params) => RenderNoteCell(params)},
  	];

	const thirdColumns: GridColDef[] = [
		{ field: 'ACTION', renderHeader: (params)=>ActionHeader(params, 3), headerClassName: 'header-cell actionCol', headerName: "", type: 'text', width: 50, editable: false, sortable: false, headerAlign:'center', renderCell: (params) => ActionCell(params)},
		{ field: 'KCATEGORIA_COSTI', headerClassName: 'header-cell categoriaCosti', headerName: "Categoria Costi", type: 'singleSelect', width: 200, editable: (loadingAction ? false : true), valueOptions: categorieCostiList},
		{ field: 'DATTIVITA', headerClassName: 'header-cell attivita', headerName: "Attività", type: 'text', flex: 1, editable: (loadingAction ? false : true)},
		{ field: 'COSTO_TOTALE', headerClassName: 'header-cell costoTotale', headerName: "Costo Totale", type: 'text', width: 150, align: 'right', headerAlign: 'right', editable: (loadingAction ? false : true), renderEditCell: (params:any) => CostCategorieCell(params), valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : ''}},
		{ field: 'NOTE', headerClassName: 'header-cell note', headerName: "Note", type: 'text', width: 55, align:'center', headerAlign:'center', editable: false, sortable: false, renderCell: (params) => RenderNoteCell(params)},
  	];

	const hiddenCols = {
		ACTION: props.proposalTotalDisabled ? false : true
	}

	const modRow = async(params:any, table:number, oldRow:any) => {
		try{
			if(props.proposalTotalDisabled){
				return oldRow;
			}

			//check per evitare insert indisiderate quando il k chiave della riga non è inserito.
			if(!params.KTALENT && (table===1 || table===2)){
				NotificationManager.warning('Non è stato selezionato un talent sulla riga', 'Attenzione', 5000);
				return oldRow;
			}else if(!params.KCATEGORIA_COSTI && table === 3){
				NotificationManager.warning('Non è stata selezionata una categoria costi sulla riga', 'Attenzione', 5000);
				return oldRow;
			}

			setLoadingAction(true);
			let dettaglioRigaParams:any = {
				kProposta: props.kProposta,
                kPropostaDettaglio: params.id,
				KTALENT: params.KTALENT,
				FEE_PERC: typeof params.FEE_PERC !== 'number' ? params.FEE_PERC === null ? '' : Number(params.FEE_PERC.replaceAll(',', '.')) : params.FEE_PERC,
				DATTIVITA: params.DATTIVITA,
				COSTO_TOTALE_FEE_INC: typeof params.COSTO_TOTALE_FEE_INC !== 'number' ? params.COSTO_TOTALE_FEE_INC === null ? '' : Number(params.COSTO_TOTALE_FEE_INC.replaceAll(',', '.')) : params.COSTO_TOTALE_FEE_INC,
				KCATEGORIA_COSTI: params.KCATEGORIA_COSTI,
				QTA: params.QTA,
				COSTO_UNITARIO: params.COSTO_UNITARIO,
				COSTO_TOTALE: typeof params.COSTO_TOTALE !== 'number' ? params.COSTO_TOTALE === null ? '' : Number(params.COSTO_TOTALE.replaceAll(',', '.')) : params.COSTO_TOTALE,
				NOTE: params.NOTE,
				impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : ''
            }
			//bisogna controllare se il ktalent è cambiato
			//se il ktalent cambia si ricarica il suo fee di default
			if(params.KTALENT !== oldRow.KTALENT){
				let foundTalent = talentList.find((tl:any) => tl.id === params.KTALENT)

				if(foundTalent === undefined){
					params.KTALENT = ''
					dettaglioRigaParams.KTALENT = '';
				}

				//cambio i parametri e la riga
				dettaglioRigaParams.FEE_PERC = foundTalent?.PERC_FEE_DEFAULT || '';
				params.FEE_PERC = foundTalent?.PERC_FEE_DEFAULT || '';
			}
			//se siamo in stato contrattualizzato e viene cambiato qualcosa che non sia la descrizione dell'attività allora passo in contrattualizzato in modifica.
			if( (params.DATTIVITA === oldRow.DATTIVITA) && (props.kPropStatus === '04')){
				props.alterStatusProposta('06');
			}

            let axiosParams = {
                url: '',
                method: 'post',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data: {
                  ...dettaglioRigaParams
                }
            }

			axiosParams.url = 'proposta-dettaglio-mod';
			let response = await axios(axiosParams);

			if(response.status === 200){
				let calculatedFields = response.data.rows[0];

				setDnValue(formatNumbersIt(calculatedFields.DN) || '');
				setMargineDoom(formatNumbersIt(calculatedFields.DOOM_MARG) || '');
				//setIncaricoAgenzia(calculatedFields);
				setCuscinetto(formatNumbersIt(calculatedFields.COSTI_EXTRA) || '');
				setTotaleCosti(formatNumbersIt(calculatedFields.TOT_COSTI) || '');
				setTotaleRicaviSenzaDn(formatNumbersIt(calculatedFields.TOT_RICAVI_DN) || '');
				setTotaleRicavi(formatNumbersIt(calculatedFields.TOT_RICAVI) || '');
				setDoomMargineDnPerc(formatNumbersIt(calculatedFields.DOOM_MARG_DN_PERC) || '');
				setDoomMargineFatturatoPerc(formatNumbersIt(calculatedFields.DOOM_MARG_FATT_PERC) || '');		

				setMediaPonderata(formatNumbersIt(calculatedFields.MEDIA_POND_FEE_PERC) || '');

				props.setMargineDoomPerc(formatNumbersIt(calculatedFields.DOOM_PERC) || '');
				props.setTotaleRicavi((calculatedFields.TOT_RICAVI) || '');

				await loadRigheDettaglio();
				//riporto sempre il valore del costo talent dai campi ricaricati dal db
				/* let rowToUpdate = response.data.rows.find((el:any) => el.id === params.id)
				params.COSTO_TOTALE = rowToUpdate.COSTO_TOTALE; */
				//e si ritorna la riga
				setLoadingAction(false)

				return params
			}
		}catch(e:any){
			setLoadingAction(false);
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
			return oldRow;
		}
	}

	const delPropostaDettaglio = async(params:any, event:any) => {
		event?.stopPropagation();
		setLoadingAction(true);
		try{
			if(props.kPropStatus === '04'){
				await props.alterStatusProposta('06');
			}

			let dettaglioRigaParams:any = {
                kPropostaDettaglio: params.id,
				impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : ''
            }

            let axiosParams = {
                url: '',
                method: 'post',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data: {
                  ...dettaglioRigaParams
                }
            }

			axiosParams.url = 'proposta-dettaglio-del';
			await axios(axiosParams);
			await loadRigheDettaglio();
			setLoadingAction(false);
			if(latestSelectedRow.id === params.id){
				setLatestSelectedRow({id: '', type: 0});
				setCurrentNoteValue('');
			}
		}catch(e:any){
			setLoadingAction(false)
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
	}

	const saveCurrentNoteDettaglio = async() => {
		try	{
			
			let row:any = undefined;
			if(latestSelectedRow.type === 1){
				row = firstRows.find((el:any)=>el.id === latestSelectedRow.id);
			}else if(latestSelectedRow.type === 2){
				row = secondRows.find((el:any)=>el.id === latestSelectedRow.id);
			}else if(latestSelectedRow.type === 3){
				row = thirdRows.find((el:any)=>el.id === latestSelectedRow.id);
			}else{
				return;
			}
			setLoadingAction(true);
			row.NOTE = currentNoteValue;

			let rigaParams:any = {
				impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : '',
				kProposta: props.kProposta,
				kPropostaDettaglio: latestSelectedRow.id,
				...row
			} 

			let axiosParams = {
                url: '',
                method: 'post',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data: {
                  ...rigaParams
                }
            }

			axiosParams.url = 'proposta-dettaglio-mod';

			let response = await axios(axiosParams);
			if(response.status === 200){
				let calculatedFields = response.data.rows[0];

				setDnValue(formatNumbersIt(calculatedFields.DN) || '');
				setMargineDoom(formatNumbersIt(calculatedFields.DOOM_MARG) || '');
				//setIncaricoAgenzia(calculatedFields);
				setCuscinetto(formatNumbersIt(calculatedFields.COSTI_EXTRA) || '');
				setTotaleCosti(formatNumbersIt(calculatedFields.TOT_COSTI) || '');
				setTotaleRicaviSenzaDn(formatNumbersIt(calculatedFields.TOT_RICAVI_DN) || '');
				setTotaleRicavi(formatNumbersIt(calculatedFields.TOT_RICAVI) || '');
				setDoomMargineDnPerc(formatNumbersIt(calculatedFields.DOOM_MARG_DN_PERC) || '');
				setDoomMargineFatturatoPerc(formatNumbersIt(calculatedFields.DOOM_MARG_FATT_PERC) || '');		

				setMediaPonderata(formatNumbersIt(calculatedFields.MEDIA_POND_FEE_PERC) || '');

				props.setTotaleRicavi((calculatedFields.TOT_RICAVI) || '');
				props.setMargineDoomPerc(formatNumbersIt(calculatedFields.DOOM_PERC) || '');

				setLoadingAction(false);
				NotificationManager.success('Nota Salvata', '', 5000);
			}
		}catch(e:any){
			setLoadingAction(false);
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
	}

	const CustomGridRow = (props:any) => (
		<GridRow
		  {...props}
		  title={props.row?.NOTE}
		/>
	)

	const handleChangeInTestataParams = async(keyCode:number | string) => {
		if(keyCode===13 || keyCode===9){
			await props.handleCheckAgenziaDn(true);
		}
		setLoadingAction(false);
	}

	const checkFieldsBeforeSubmit = async(tastoPremuto:any) => {
		if((testNumberInputPercentagesTwoDecimals(props.dn) || props.dn === 0 || props.dn === '') && (testNumberInputPercentagesTwoDecimals(props.margineDoomPerc) || props.margineDoomPerc === 0 || props.margineDoomPerc === '')){
			setLoadingAction(true);
			await handleChangeInTestataParams(tastoPremuto)
		}else{
			return false;
		}
	}
	
	const [cellModesModel,  setCellModesModel] = React.useState<GridCellModesModel>({});
	const [cellModesModel2, setCellModesModel2] = React.useState<GridCellModesModel>({});
	const [cellModesModel3, setCellModesModel3] = React.useState<GridCellModesModel>({});
	
	const handleCellClick = React.useCallback(
		(params: GridCellParams, event: React.MouseEvent, gridFunction: any) => {
		  if (!params.isEditable) {
			gridFunction((prevModel:any) => {
				return {
				  // Revert the mode of the other cells from other rows
				  ...Object.keys(prevModel).reduce(
					(acc, id) => ({
					  ...acc,
					  [id]: Object.keys(prevModel[id]).reduce(
						(acc2, field) => ({
						  ...acc2,
						  [field]: { mode: GridCellModes.View },
						}),
						{},
					  ),
					}),
					{},
				  ),
				  [params.id]: {
					// Revert the mode of other cells in the same row
					...Object.keys(prevModel[params.id] || {}).reduce(
					  (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
					  {},
					)
				  },
				};
			});
			return;
		  }
	
		  // Ignore portal
		  if (
			(event.target as any).nodeType === 1 &&
			!event.currentTarget.contains(event.target as Element)
		  ) {
			return;
		  }
	
		  gridFunction((prevModel:any) => {
			return {
			  // Revert the mode of the other cells from other rows
			  ...Object.keys(prevModel).reduce(
				(acc, id) => ({
				  ...acc,
				  [id]: Object.keys(prevModel[id]).reduce(
					(acc2, field) => ({
					  ...acc2,
					  [field]: { mode: GridCellModes.View },
					}),
					{},
				  ),
				}),
				{},
			  ),
			  [params.id]: {
				// Revert the mode of other cells in the same row
				...Object.keys(prevModel[params.id] || {}).reduce(
				  (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
				  {},
				),
				[params.field]: { mode: GridCellModes.Edit },
			  },
			};
		  });
		},
		[],
	);
	
	const handleCellModesModelChange = React.useCallback(
		(newModel: GridCellModesModel, grid: number) => {
		  if (grid === 1) setCellModesModel(newModel);
		  if (grid === 2) setCellModesModel2(newModel);
		  if (grid === 3) setCellModesModel3(newModel);
		},
		[]
	);

	return (
    	<div className='corpo-proposta-container'>
      		<div className='grids-container'>
				{isFirstBoxToRender && (
					<DataGridPremium
						className='dataGrid'
						slots={{
							noRowsOverlay: ()=>loadingAction&&(<div style={{display: 'flex', alignItems:'center', justifyContent:'center', height:'100%'}}><CircularProgress/></div>),
							row: CustomGridRow,
						}}
						initialState={{ pinnedColumns: { left: ['ACTION'], right: ['FEE_PERC', 'COSTO_TOTALE_FEE_INC', 'COSTO_TOTALE','NOTE'] } }}
						rows={firstRows}
						columns={firstColumns}
						columnVisibilityModel={hiddenCols}
						rowHeight={30}
						hideFooter={true}
						isCellEditable={(params) => params.id!==0}
						disableColumnMenu={true}
						disableColumnReorder={true}
						getCellClassName={(params:GridCellParams) => {if(params.field==="COSTO_TOTALE"){ return 'non-editable-cell'}else{return ''}}}
						processRowUpdate={(params:any, oldRow:any)=>modRow(params,1, oldRow)}
						onProcessRowUpdateError={(error:any) => {console.log(error)}}
						onRowClick={(params:GridRowParams) => setLatestSelectedRow({id: params.id, type: 1, note: params.row.NOTE || ''})}
        				cellModesModel={cellModesModel}
        				onCellModesModelChange={(newModel) => handleCellModesModelChange(newModel, 1)}
        				onCellClick={(params, event) => handleCellClick(params, event, setCellModesModel)}
					/>
				)}
				{isSecondBoxToRender &&(
					<DataGridPremium
						className='dataGrid'
						slots={{
							noRowsOverlay: ()=>loadingAction&&(<div style={{display: 'flex', alignItems:'center', justifyContent:'center', height:'100%'}}><CircularProgress/></div>),
							row: CustomGridRow,
						}}
						initialState={{ pinnedColumns: { left: ['ACTION'], right: ['COSTO_TOTALE', 'NOTE'] } }}
						rows={secondRows}
						columns={secondColumns}
						columnVisibilityModel={hiddenCols}
						rowHeight={30}
						hideFooter={true}
						/* headerHeight={40} */
						isCellEditable={(params) => params.id!==0}
						disableColumnMenu={true}
						disableColumnReorder={true}
						processRowUpdate={(params:any, oldRow:any)=>modRow(params,2, oldRow)}
						onProcessRowUpdateError={(error:any) => {console.log(error)}}
						onRowClick={(params:GridRowParams) => setLatestSelectedRow({id: params.id, type: 2, note: params.row.NOTE || ''})}
						cellModesModel={cellModesModel2}
        				onCellModesModelChange={(newModel) => handleCellModesModelChange(newModel, 2)}
        				onCellClick={(params, event) => handleCellClick(params, event, setCellModesModel2)}
					/>
				)}
				{ isThirdBoxToRender &&(
					<DataGridPremium
						className='dataGrid'
						slots={{
							noRowsOverlay: ()=>loadingAction&&(<div style={{display: 'flex', alignItems:'center', justifyContent:'center', height:'100%'}}><CircularProgress/></div>),
							row: CustomGridRow,
						}}
						initialState={{ pinnedColumns: { left: ['ACTION'], right: ['COSTO_TOTALE', 'NOTE'] } }}
						rows={thirdRows}
						columns={thirdColumns}
						columnVisibilityModel={hiddenCols}
						rowHeight={30}
						hideFooter={true}
						/* headerHeight={40} */
						isCellEditable={(params) => params.id!==0}
						disableColumnMenu={true}
						disableColumnReorder={true}
						processRowUpdate={(params:any, oldRow:any)=>modRow(params,3, oldRow)}
						onProcessRowUpdateError={(error:any) => {console.log(error)}}
						onRowClick={(params:GridRowParams) => setLatestSelectedRow({id: params.id, type: 3, note: params.row.NOTE || ''})}
						cellModesModel={cellModesModel3}
        				onCellModesModelChange={(newModel) => handleCellModesModelChange(newModel, 3)}
        				onCellClick={(params, event) => handleCellClick(params, event, setCellModesModel3)}
					/>
				)}
			</div>
			<div className='configuration-block'>
				<ThreeVerticalDot addClass='options-menu' onClick={(event:any)=>handleOpenMenu(event)}/>
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={openMenu}
					onClose={handleClose}
					MenuListProps={{
					'aria-labelledby': 'basic-button',
					}}
				>
					<MenuItem>
						<FormGroup>  
							<FormControlLabel control={<Switch color="secondary" checked={isFirstBoxToRender} onChange={(event)=>{setIsFirstBoxToRender(!isFirstBoxToRender)}}/>} label="Talent Interni" />
						</FormGroup>
					</MenuItem>
					<MenuItem>
						<FormGroup>
							<FormControlLabel control={<Switch color="secondary" checked={isSecondBoxToRender} onChange={(event)=>{setIsSecondBoxToRender(!isSecondBoxToRender)}}/>} label="Talent Esterni" />
						</FormGroup>
					</MenuItem>
					<MenuItem>
						<FormGroup>
							<FormControlLabel control={<Switch color="secondary" checked={isThirdBoxToRender} onChange={(event)=>{setIsThirdBoxToRender(!isThirdBoxToRender)}}/>} label="Costi" />
						</FormGroup>
					</MenuItem>
				</Menu>
				<div className='calculated-fields'>
					<div className='parameters'>
						<TextField 	
							id="standard-basic" 
							error={(props.dn || props.dn === 0) ? !testNumberInputPercentagesTwoDecimals(props.dn) : props.dn === '' ? false : true} 
							label="DN" 
							variant="standard" 
							value={props.dn} 
							onKeyUp={async(event:any) => {checkFieldsBeforeSubmit(event.keyCode)}} 
							onChange={(event) => {props.setDn(event.target.value)}} 
							InputProps = {{ endAdornment: <PercentageIcon addClass='pad-left'/> }} 
							inputProps={{sx:{textAlign:'right'}}} 
							className='percentage-text-field-dn'
							disabled = {(!props.kAgenziaSelected) || (props.proposalShouldBeDisabled || props.proposalTotalDisabled)}
							autoComplete='off'
						/>
						<TextField 
							id="standard-basic" 
							error={(props.margineDoomPerc || props.margineDoomPerc === 0) ? !testNumberInputPercentagesTwoDecimals(props.margineDoomPerc) : props.margineDoomPerc === '' ? false : true}
							label="Margine Doom" 
							variant="standard" 
							value={props.margineDoomPerc} 
							onKeyUp={async(event:any) => {checkFieldsBeforeSubmit(event.keyCode)}} 
							onChange={(event) => {props.setMargineDoomPerc( event.target.value )} } 
							inputProps={{sx:{textAlign:'right'}}} 
							InputProps = {{ endAdornment: <PercentageIcon addClass='pad-left'/> }} 
							className='percentage-text-field-margine'
							disabled = {props.proposalTotalDisabled}
							autoComplete='off'
						/>
						<TextField 
							id="standard-basic" 
							label={props.bBudget ? "Budget" : "Budget Obiettivo"} 
							variant="standard" 
							value={props.bBudget ? props.budget : props.target} 
							onKeyUp={async(event:any) => {setLoadingAction(true);await handleChangeInTestataParams(event.keyCode)}} 
							onChange={(event) => {props.bBudget ? props.setBudget(event.target.value) : props.setTarget(event.target.value)}} 
							inputProps={{sx:{textAlign:'right'}}} 
							InputProps= {{ endAdornment: <EuroIcon addClass='pad-left'/> }}
							disabled = {props.proposalTotalDisabled}
							autoComplete='off'
							className={props.bBudget ? 'totale-ricavi-bg' : ''}
						/>
					</div>
					<div className='riepilogo'>
						<TextField id="standard-basic" variant="outlined" label="Totale Costi" value={totaleCosti} inputProps={{readOnly: true,sx:{textAlign:'right'}, className:'resized-pad-text-field'}} InputProps= {{ endAdornment: <EuroIcon addClass='pad-left'/> }}/>
						{props.bBudget ? (
							<>
								<TextField id="standard-basic" variant="outlined" label="DN" value={dnValue} inputProps={{readOnly: true,sx:{textAlign:'right'}, className:'resized-pad-text-field'}} InputProps= {{ endAdornment: <EuroIcon addClass='pad-left'/> }}/>
								<TextField id="standard-basic" variant="outlined" label="Margine Doom" value={margineDoom} inputProps={{readOnly: true,sx:{textAlign:'right'}, className:'resized-pad-text-field'}} InputProps= {{ endAdornment: <EuroIcon addClass='pad-left'/> }}/>
								<TextField id="standard-basic" variant="outlined" label="Costi Extra Cuscinetto" className='costi-extra-cuscinetto-bg' value={cuscinetto} inputProps={{readOnly: true,sx:{textAlign:'right'}, className:'resized-pad-text-field'}} InputProps= {{ endAdornment: <EuroIcon addClass='pad-left'/> }}/>
							</>								
						):(
							<>
								<TextField id="standard-basic" variant="outlined" label="Margine Doom" value={margineDoom} inputProps={{readOnly: true,sx:{textAlign:'right'}, className:'resized-pad-text-field'}} InputProps= {{ endAdornment: <EuroIcon addClass='pad-left'/> }}/>
								<TextField id="standard-basic" variant="outlined" label="Totale Ricavi senza DN" value={totaleRicaviSenzaDn} inputProps={{readOnly: true,sx:{textAlign:'right'}, className:'resized-pad-text-field'}} InputProps= {{ endAdornment: <EuroIcon addClass='pad-left'/> }}/>
								<TextField id="standard-basic" variant="outlined" label="DN" value={dnValue} inputProps={{readOnly: true,sx:{textAlign:'right'}, className:'resized-pad-text-field'}} InputProps= {{ endAdornment: <EuroIcon addClass='pad-left'/> }}/>
								<TextField id="standard-basic" variant="outlined" label="Totale Ricavi" className='totale-ricavi-bg' value={totaleRicavi} inputProps={{readOnly: true,sx:{textAlign:'right'}, className:'resized-pad-text-field'}} InputProps= {{ endAdornment: <EuroIcon addClass='pad-left'/> }}/>
							</>
						)}
						<div className='flexed-gap'>
							<TextField id="standard-basic" variant="outlined" title='Margine Doom Al Lordo Del DN' label="Margine Doom Al Lordo Del DN" value={doomMargineDnPerc} inputProps={{readOnly: true,sx:{textAlign:'right'}, className:'resized-pad-text-field'}}  InputProps = {{ endAdornment: <PercentageIcon addClass='pad-left'/> }} sx={{width: '50%'}}/>
							<TextField id="standard-basic" variant="outlined" title='Margine Doom Effettivo Sul Fatturato' label="Margine Doom Effettivo Sul Fatturato" value={doomMargineFatturatoPerc} inputProps={{readOnly: true,sx:{textAlign:'right'}, className:'resized-pad-text-field'}}  InputProps = {{ endAdornment: <PercentageIcon addClass='pad-left'/> }} sx={{width: '50%'}}/>
						</div>

						{/* <TextField id="standard-basic" label="Incarico Agenzia" variant="outlined" value={incaricoAgenzia} onChange={(event) => setIncaricoAgenzia(event.target.value)} /> */}
						<TextField id="standard-basic" label="Media Ponderata" variant="outlined" value={mediaPonderata} onChange={(event) => setMediaPonderata(event.target.value)} inputProps={{readOnly: true,sx:{textAlign:'right'}, className:'resized-pad-text-field'}}  InputProps = {{ endAdornment: <PercentageIcon addClass='pad-left'/> }}/>
					</div>
				</div>
				<div className='textarea-zone relative'>
					<textarea className='notes-textarea' rows={4} cols={50} maxLength={4000} value={currentNoteValue || ''} onChange={(event) => setCurrentNoteValue(event?.target.value)} disabled = {props.proposalTotalDisabled || latestSelectedRow.id===''}/>
					<div className='characters-left'>{(currentNoteValue || '').length}/4000</div>
					{!(props.proposalTotalDisabled) 
						?
							loadingAction
							? 
								<CircularProgress style={{right: '10px'}} className='textarea-save-note table-circular-progress'/> 
							: 
								<IconButton  className='textarea-save-note' onClick={saveCurrentNoteDettaglio} disabled={latestSelectedRow.id===''}><SaveIcon/></IconButton> 
						: 
							null
					}
				</div>
			</div>
		</div>
  	)
});

export default Proposta